import ReactDOM from 'react-dom';
import { Typography, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { BOLETA_PNG, CLOSE_CIRCLE } from 'assets/images';
import ModalGlobalStyles from 'utils/styles/ModalGlobalStyles';
import ModalNumeroClienteStyles from './ModalNumeroClienteStyles';

const ModalNumeroCliente = ({ cerrarModal }: { cerrarModal: () => void }) => {
  /**
   * Selecciona el elemento modal
   */
  const element = document.getElementById('modal')!;

  /**
   * Componente styles
   */
  const styles = ModalNumeroClienteStyles();
  /**
   * Componente styles de modal global
   */
  const stylesGlobal = ModalGlobalStyles();

  /**
   * Componente de traduccion i18n
   */
  const { t } = useTranslation();

  return (
    element &&
    ReactDOM.createPortal(
      <div className={stylesGlobal.backdrop}>
        <section className={`${styles.modalGlobal}`}>
          <div className={styles.contenedorTitulo}>
            <Typography className={stylesGlobal.tituloTextos}>
              {t('tracking:paginas.tracking.cicloFacturacion.contenido.modal.titulo')}
            </Typography>
          </div>

          <div className={styles.contenedorInterno}>
            <Typography className={styles.parrafoGlobal}>
              {t('tracking:paginas.tracking.cicloFacturacion.contenido.modal.subTitulo')}
            </Typography>
            <img className={styles.imagenBoleta} src={BOLETA_PNG} alt="Boleta" />
          </div>

          <div className={styles.contenedorCerrar}>
            <IconButton aria-label="cerrar" onClick={cerrarModal} data-testid="cerrarModal">
              <img src={CLOSE_CIRCLE} alt="cerrar" />
            </IconButton>
          </div>
        </section>
      </div>,
      element
    )
  );
};

export default ModalNumeroCliente;
