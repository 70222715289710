import { createStyles, makeStyles, Theme } from '@material-ui/core';

const AvisoPagoCortesStyles = makeStyles((theme: Theme) =>
  createStyles({
    avisoCorteCnt: {
      width: '100%',
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'center',
      margin: '2.125rem auto 0 auto',
      background: 'var(--gris-l96)',
      borderRadius: '0.625rem',
      padding: '1.75rem',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        minWidth: 'auto',
        padding: '1.75rem 1.25rem',
        maxWidth: '422px',
      },
    },
    dataCorte: {
      width: '100%',
      display: 'flex',
      marginBottom: '1rem',
      [theme.breakpoints.down('sm')]: {
        flexFlow: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        marginBottom: '0',
      },
    },
    label: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      marginBottom: '0.25rem',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
        textAlign: 'left',
      },
    },
    item: {
      flexGrow: 41,
      [theme.breakpoints.down('sm')]: {
        marginBottom: '1rem',
      },
    },
    info: {
      textAlign: 'left',
      marginBottom: '0.25rem',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },
    sinInfo: {
      textAlign: 'left',
    },
  })
);

export default AvisoPagoCortesStyles;
