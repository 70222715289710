import { TipoTab } from 'config/tipoTab';

/**
 * Arreglo que contiene las opciones de búsqueda de la boleta por el usuario
 *
 * @param busquedaPorRutAtivada indica si la búsqueda por RUT está activada
 * @returns Arreglo con las opciones de búsqueda correspondientes
 */
export const subTabsSeleccionables = (busquedaPorRutAtivada: boolean, t: any) => [
  {
    opcion: TipoTab.NUMERO_CLIENTE,
    texto: t('tracking:paginas.tracking.cicloFacturacion.busquedaPorRut.itemBusquedas.busquedaNumero.itemSubTab'),
    esSeleccionado: false,
  },
  ...(busquedaPorRutAtivada
    ? [
        {
          opcion: TipoTab.RUT,
          texto: t('tracking:paginas.tracking.cicloFacturacion.busquedaPorRut.itemBusquedas.busquedaRut.itemSubTab'),
          esSeleccionado: false,
        },
      ]
    : []),
];
