import { createStyles, makeStyles, Theme } from '@material-ui/core';

const ContenedorDeItemBusquedaStyles = makeStyles((theme: Theme) =>
  createStyles({
    contenedorInternoData: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '28px 0px',
      gap: '20px',
      width: '332px',
      height: 'auto',
      background: 'var(--gris-l96)',
      borderRadius: '10px',
      marginBottom: '32px',
      [theme.breakpoints.down('sm')]: {
        padding: '28px 20px',
        maxWidth: '100%',
      },
    },

    seleccion: {
      fontFamily: 'Neutrif Studio',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '18px',
      color: 'var(--gris-l3)',
      paddingTop: '4px',
      paddingBottom: '4px',
      [theme.breakpoints.down('sm')]: {
        paddingBottom: '0px',
      },
    },
    inputRoot: {
      display: 'flex',
      alignItems: 'center',
      height: '45px',
      [theme.breakpoints.down('sm')]: {
        paddingRight: '4px',
      },
    },
    inputInput: {
      paddingLeft: '14px',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '14px',
      },
    },
    inputNumero: {
      width: '250px',
      height: '45px',
      backgroundColor: 'var(--blanco)',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    inputRut: {
      width: '250px',
      height: '45px',
      backgroundColor: 'var(--blanco)',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    botonInput: {
      padding: '0px',
    },
    labelRoot: {
      transform: ' translate(14px, 14px) scale(1)',
    },

    imagenBoleta: {
      width: '342px',
      height: '247px',
      [theme.breakpoints.down('sm')]: {},
    },
    botonIr: {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
  })
);

export default ContenedorDeItemBusquedaStyles;
