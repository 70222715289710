export const ROOT = '/';

// Flujos Principales
export const CICLO_FACTURACION = '/facturacion';
export const CICLO_FACTURACION_SLASH = '/facturacion/';
export const CICLO_FACTURACION_DETALLE = '/facturacion/detalle';
export const CICLO_FACTURACION_DETALLE_TRAMO = '/facturacion/detalle/mpc';
export const CORTE = '/corte';
export const POST_VENTA = '/consultas-y-reclamos';

// Ciclo Comercial
export const TOMA_LECTURA = 'toma-lectura';
export const EMISION_BOLETA = 'emision-boleta';
export const ENTREGA_BOLETA = 'entrega-boleta';
export const VENCIMIENTO_BOLETA = 'vencimiento-boleta';
export const EN_PREPARACION = 'preparacion';
export const EMITIDA = 'emitida';
export const ENTREGADA = 'entregada';
export const NO_ENTREGADA = 'no-entregada';
export const POR_VENCER = 'por-vencer';
export const PAGADA = 'pagada';
export const VENCIDA = 'vencida';
export const EN_PROCESO = 'proceso';
export const EXITO = 'exito';
export const SIN_EXITO = 'sin-exito';

// Cortes
export const DETALLE = 'detalle';

// Flujos de cortes compartidos
export const CORTE_SUSPENDIDO = 'corte-suspendido';
export const SERVICIO_ACTIVO = 'servicio-activo';
export const CORTE_INEXISTENTE = 'corte-inexistente';

// PostVenta
export const LISTADO_TICKETS = 'listado';
export const DETALLE_TICKET = 'detalle';

// Otros
export const GRUPO_SAESA_WEB_URL = 'https://web.gruposaesa.cl/';
export const HOME_PRIVADO_URL = `${process.env.REACT_APP_FUSION_AUTH_REDIRECT_URI}`;
export const LINK_PAGO = `${process.env.REACT_APP_LINK_PAGO}`;
export const ERROR_N_SERVICIO_NO_EXISTE = 'ERROR_N_SERVICIO_NO_EXISTE';
export const ERROR_N_SERVICIO_SIN_INSTANCIA = 'ERROR_N_SERVICIO_SIN_INSTANCIA';
export const URL_ESTOY_SIN_LUZ = `${process.env.REACT_APP_URL_ESTOY_SIN_LUZ}`;

// Alza de precio de la luz
export const ALZA_PRECIO_LUZ = 'mpc';
