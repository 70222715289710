import { infoTramo } from 'assets/images';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import WidgetTrackingHelperStyles from './WidgetTrackingHelperStyles';

const WidgetTrackingHelper = () => {
  /**
   * Variable que maneja el uso de estilos.
   */
  const styles = WidgetTrackingHelperStyles();

  /**
   * Componente de traduccion i18n
   */
  const { t } = useTranslation();

  return (
    <div className={styles.contenedor}>
      <div className={styles.contenedorInformacion}>
        <img src={infoTramo} alt="info-tramo" />
        <div className={styles.contenedorTexto}>
          <Typography className={styles.textoParrafo}>
            {t('tracking:paginas.tracking.cicloFacturacion.widgetFacturacionPantallaInicio.titulo')}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default WidgetTrackingHelper;
