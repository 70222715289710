import axios from 'axios';

/**
 * Función que verifica si la url contiene parte de assets
 * @param url que sera verificar
 * @returns true si la url es de assets
 */
const checkAssetsUrl = (url: string): boolean => url.includes(process.env.REACT_APP_ASSETS_URL!);

const mostrarLoading = () => {
  let contador: string | number | null = sessionStorage.getItem('loading');
  contador = parseInt(contador!, 10) + 1;
  sessionStorage.setItem('loading', contador.toString());
  const addBackDrop: HTMLElement | null | void = document.getElementById('loading');
  if (addBackDrop !== null) {
    addBackDrop.classList.add('backdrop--show');
  }
};

const AuthInterceptor = () => {
  const apiKey = `${process.env.REACT_APP_HEADER_API_KEY}`;
  axios.interceptors.request.use(request => {
    mostrarLoading();
    if (!checkAssetsUrl(request.url!)) {
      request.headers['x-api-key'] = apiKey;
    }
    const token = localStorage.getItem('token');
    if (!checkAssetsUrl(request.url!)) {
      if (!token || request.headers.Authorization) {
        request.headers.Authorization = request.headers.Authorization || null;
      } else {
        request.headers.Authorization = `Bearer ${token}`;
      }
    }
    return request;
  });
};

export default AuthInterceptor;
