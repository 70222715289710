import { createStyles, makeStyles, Theme } from '@material-ui/core';

const ItemsBusquedaRutStyles = makeStyles((theme: Theme) =>
  createStyles({
    contenedorGlobal: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      padding: '18px 16px',
      gap: '6px',
      isolation: 'isolate',
      width: '307px',
      height: '102px',
      background: '#F7F5FF',
      borderRadius: '10px',
      flex: 'none',
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 52px)',
        maxWidth: '100%',
        padding: '16px',
      },
    },

    itemParrafos: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '0px',
      gap: '8px',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
    },

    tituloTextos: {
      fontFamily: 'Neutrif Studio',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '18px',
      textAlign: 'center',
      color: 'var(--gris-l3)',
      flex: 'none',
      order: 0,
      flexGrow: 0,
      [theme.breakpoints.down('sm')]: {
        width: 'auto',
      },
    },

    parrafoGlobal: {
      fontFamily: 'Neutrif Studio',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '18px',
      color: 'var(--gris-l3)',
      flex: 'none',
      alignSelf: 'stretch',
      flexGrow: 0,

      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },

    imagenBoleta: {
      width: '342px',
      height: '247px',
      [theme.breakpoints.down('sm')]: {},
    },
  })
);

export default ItemsBusquedaRutStyles;
