import { createStyles, makeStyles, Theme } from '@material-ui/core';

const avisoPagoComercialStyles = makeStyles((theme: Theme) =>
  createStyles({
    avisoCnt: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      margin: '2.125rem auto 0 auto',
      background: 'var(--gris-l96)',
      gap: '72px',
      borderRadius: '0.625rem',
      padding: '28px 22px',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        minWidth: 'auto',
        padding: '1.75rem .875rem',
        maxWidth: '422px',
        gap: '24px',
      },
    },
    avisoCntFitContent: {
      display: 'flex',
      alignItems: 'center',
      margin: '2.125rem auto 0 auto',
      background: 'var(--gris-l96)',
      borderRadius: '0.625rem',
      padding: '28px 32px',
      gap: '24px',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        width: 'auto',
        minWidth: 'auto',
        padding: '28px 32px',
        maxWidth: '350px',
      },
    },
    infoErrorCnt: {
      alignItems: 'center',
      marginLeft: '-10px',
      width: '314px',
      [theme.breakpoints.down('sm')]: {
        marginLeft: '0',
        width: '100%',
        marginTop: '-16px',
      },
    },
    contenedorBoton: {
      display: 'flex',
      gap: '24px',
      maxWidth: '300px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    botonIr: {
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    label: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      marginBottom: '0.25rem',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
        textAlign: 'center',
      },
    },
    info: {
      textAlign: 'left',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
        textAlign: 'center',
      },
    },
    infoPendiente: {
      textAlign: 'left',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
        textAlign: 'center',
      },
    },
    iconSuccess: {
      marginRight: '.5rem',
      '--icon-primary-dark': 'var(--secundario-azul-marino)',
      '--icon-primary-light': 'var(--verde-exito)',
    },
    iconError: {
      '--icon-primary-dark': 'var(--secundario-azul-marino)',
      '--icon-primary-light': 'var(--rojo-error)',
      [theme.breakpoints.down('sm')]: {
        marginRight: '0',
      },
    },
    contenedorMobile: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    iconExport: {
      marginLeft: '.5rem',
    },
    pagadoIcono: {
      flexGrow: 45,
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        marginBottom: '1rem',
      },
    },
    pagadoFecha: {
      flexGrow: 41,
      [theme.breakpoints.down('sm')]: {
        borderRight: '2px solid var(--gris-l83)',
      },
    },
    pagadoMonto: {
      flexGrow: 13,
      [theme.breakpoints.down('sm')]: {
        flexGrow: 41,
      },
    },
    vencimientoEmision: {
      width: '82px',
      marginRight: '-24px',
      [theme.breakpoints.down('sm')]: {
        width: 'auto',
        borderRight: '2px solid var(--gris-l83)',
        paddingRight: '28px',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        marginRight: '0px',
      },
    },
    vencimiento: {
      width: '82px',
      marginRight: '-24px',
      [theme.breakpoints.down('sm')]: {
        width: 'auto',
        marginRight: '0px',
      },
    },
    sinInfo: {
      color: 'var(--gris-l43)',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
    vencimientoMonto: {
      width: 'auto',
      [theme.breakpoints.down('sm')]: {
        width: 'auto',
      },
    },
    vencimientoBoton: {
      marginLeft: '-28px',
      width: '190px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginLeft: '0px',
      },
    },
    vencimientoContenedor: {
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        gap: '28px',
        justifyContent: 'center',
        width: '100%',
        marginTop: '-16px',
      },
    },
    verBoletaVencida: {
      display: 'flex',
      textDecoration: 'none',
      gap: '8px',
      [theme.breakpoints.down('sm')]: {},
    },
    verBoletaPendiente: {
      display: 'flex',
      textDecoration: 'none',
      gap: '8px',
    },

    lineaSeparadora: {
      height: '55px',
      border: '1px solid var(--gris-l83)',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    textoBoton: {
      fontFamily: 'Neutrif Studio',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '18px',
      color: 'var(--primario-morado)',
      flex: 'none',
      order: 1,
      flexGrow: 0,
    },
  })
);

export default avisoPagoComercialStyles;
