const PuntoMapa = ({ className }: { className?: string }) => (
  <svg className={className} width="15" height="21" viewBox="0 0 15 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.5 3.75C6.75832 3.75 6.0333 3.96993 5.41661 4.38199C4.79993 4.79404 4.31928 5.37971 4.03545 6.06494C3.75162 6.75016 3.67736 7.50416 3.82206 8.23159C3.96675 8.95902 4.3239 9.6272 4.84835 10.1517C5.3728 10.6761 6.04098 11.0333 6.76841 11.1779C7.49584 11.3226 8.24984 11.2484 8.93506 10.9645C9.62029 10.6807 10.206 10.2001 10.618 9.58339C11.0301 8.9667 11.25 8.24168 11.25 7.5C11.2489 6.50576 10.8535 5.55255 10.1505 4.84952C9.44745 4.14649 8.49424 3.75106 7.5 3.75ZM7.5 10C7.00555 10 6.5222 9.85338 6.11107 9.57867C5.69995 9.30397 5.37952 8.91352 5.1903 8.45671C5.00108 7.99989 4.95157 7.49723 5.04804 7.01227C5.1445 6.52732 5.3826 6.08186 5.73223 5.73223C6.08186 5.3826 6.52732 5.1445 7.01227 5.04804C7.49723 4.95157 7.99989 5.00108 8.45671 5.1903C8.91352 5.37952 9.30397 5.69995 9.57867 6.11107C9.85338 6.5222 10 7.00555 10 7.5C10 8.16304 9.73661 8.79893 9.26777 9.26777C8.79893 9.73661 8.16304 10 7.5 10ZM7.5 0C5.51088 0 3.60322 0.790176 2.1967 2.1967C0.790176 3.60322 0 5.51088 0 7.5C0 10.524 1.054 11.368 6.729 19.6C6.81533 19.7246 6.93056 19.8264 7.06484 19.8967C7.19911 19.967 7.34843 20.0038 7.5 20.0038C7.65157 20.0038 7.80089 19.967 7.93516 19.8967C8.06944 19.8264 8.18467 19.7246 8.271 19.6C13.946 11.368 15 10.524 15 7.5C15 5.51088 14.2098 3.60322 12.8033 2.1967C11.3968 0.790176 9.48912 0 7.5 0V0ZM7.5 18.513C2.059 10.644 1.25 10.019 1.25 7.5C1.25017 6.26399 1.61681 5.05578 2.30358 4.02812C2.99035 3.00047 3.96641 2.19952 5.10834 1.72654C6.25028 1.25356 7.50681 1.12979 8.71908 1.37088C9.93136 1.61197 11.0449 2.20709 11.919 3.081C12.5012 3.65993 12.9628 4.34858 13.2771 5.1071C13.5914 5.86562 13.7521 6.67895 13.75 7.5C13.75 10.019 12.942 10.643 7.5 18.513Z"
      fill="white"
    />
  </svg>
);

PuntoMapa.defaultProps = {
  className: '',
};
export default PuntoMapa;
