import { createStyles, makeStyles, Theme } from '@material-ui/core';

const ModalSeleccionarNumeroClienteStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalGlobal: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      background: 'var(--blanco)',
      padding: '44px',
      borderRadius: '15px',
      boxShadow: 'var(--box-shadow)',
      transform: 'translate(-50%, -50%)',
      zIndex: 4,
      gap: '12px',
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        width: '90%',
        padding: '44px 20px 32px 20px',
      },
    },

    parrafoGlobal: {
      fontFamily: 'Neutrif Studio',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '18px',
      color: 'var(--gris-l3)',
      flex: 'none',
      alignSelf: 'stretch',
      flexGrow: 0,
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },

    contenedorCerrar: {
      position: 'fixed',
      top: 0,
      right: 0,
      padding: '2vw',
    },
    contenedorTitulo: {
      marginBottom: '20px',
    },
    contenedorInterno: {
      display: 'flex',
      flexDirection: 'column',
      gap: '12px',
      maxHeight: '364px',
      overflowX: 'hidden',
      overflowY: 'auto',

      /* Estilos de scrollbar */
      '&::-webkit-scrollbar': {
        width: '9px',
        borderRadius: '18px',
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: '#CCCFDB',
        borderRadius: '18px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#9196B1',
        borderRadius: '18px',
        minHeight: '47.8px',
        maxHeight: '47.8px',
      },
      '&::-webkit-scrollbar-button': {
        display: 'none',
      },
    },
    imagenBoleta: {
      width: '342px',
      height: '247px',
      [theme.breakpoints.down('sm')]: {},
    },
    labelRadioButton: {
      padding: '0px',
    },
    radioButton: {
      marginLeft: '0px',
      marginRight: '0px',
    },
    contenedorButtonTexto: {
      display: 'flex',
      flexDirection: 'row',
      gap: '14px',
      width: '360px',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
        width: 'auto',
      },
    },
  })
);

export default ModalSeleccionarNumeroClienteStyles;
