import { Button, Hidden } from '@material-ui/core';
import { BoletaEstadoIcon, Typography } from '@saesa/ui-kit-front';
import { TipoPagoBoleta } from 'config';
import { obtenerTextoAvisoPagoCC, obtenerTextoAvisoPagoCCPagado, obtenerTextoAvisoPagoCCPendientePago } from 'helper/trackingCC.helper';
import { AvisoPagoModel } from 'models';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import FormatValues from 'utils/FormatValues';
import { convertToHttps } from 'utils/conversionDireccion';
import { verUltimaBoleta } from 'assets/images';
import { tagFacturacionBotonPago, tagFacturacionVerBoleta } from 'utils/gtmEventsUtils';
import TrackingService from 'services/TrackingService';
import { useSnackbar } from 'notistack';
import useStyles from './AvisoPagoComercialStyles';

const AvisoPagoComercial = ({ className, modeloAvisoPago }: { className?: string; modeloAvisoPago: AvisoPagoModel }) => {
  const { fechaEmision, fechaPago, fechaVencimiento, monto, tipoPago, linkPaperlessBoleta } = modeloAvisoPago;

  /**
   * Uso del i18n para traducciones
   */
  const { t } = useTranslation();

  /**
   * Variable que maneja el uso de estilos.
   */
  const styles = useStyles();
  /**
   * Selector de redux
   */
  const usuario = useSelector((state: RootState) => state.tracking.usuario);
  /**
   * Componente de pildora
   */
  const { enqueueSnackbar } = useSnackbar();
  /**
   * URL base de pago
   */
  const urlPagoRoot = new URL(process.env.REACT_APP_URL_PAGO || '');

  const onClickPago = () => {
    const data = {
      numero: usuario.numeroServicio,
    };
    tagFacturacionBotonPago(data);

    TrackingService.validarMedioPago(usuario.numeroServicio)
      .then(response => {
        urlPagoRoot.searchParams.set('id', response?.idPago);
        window.location.replace(urlPagoRoot.toString());
      })
      .catch(error => {
        enqueueSnackbar(error?.response?.data?.mensaje, {
          variant: 'error',
        });
      });
  };

  /**
   * Función que aplica tag al hacer click en ver boleta o ver última boleta.
   */
  const onClickVerBoleta = () => {
    const data = {
      numero: usuario.numeroServicio,
    };
    tagFacturacionVerBoleta(data);
  };

  /**
   * Valida con un switch el estado actual
   * @returns Retorna un RactNode
   */
  const contenidoPorTipo = () => {
    switch (tipoPago) {
      case TipoPagoBoleta.VENCIDO:
        return (
          <section className={styles.avisoCntFitContent}>
            <BoletaEstadoIcon className={styles.iconError} />
            <section className={styles.infoErrorCnt}>
              <Typography className={styles.label} strong="semi-bold" variant="parrafo">
                {t('tracking:paginas.tracking.cicloFacturacion.avisoPago.textos.pagoPendiente')}
              </Typography>
              <Typography className={styles.info} strong="normal" variant="parrafo">
                {t('tracking:paginas.tracking.cicloFacturacion.avisoPago.textos.mantenBoletaAlDia')}
              </Typography>
            </section>
            <div className={styles.lineaSeparadora} />
            <a
              className={styles.verBoletaVencida}
              onClick={onClickVerBoleta}
              href={convertToHttps(linkPaperlessBoleta)}
              target="_blank"
              rel="noreferrer"
              download
            >
              <img src={verUltimaBoleta} alt="ultima boleta" />
              <Typography className={styles.textoBoton} variant="parrafo">
                {t('tracking:paginas.tracking.cicloFacturacion.avisoPago.textos.verUltimaBoleta')}
              </Typography>
            </a>
            <section className={styles.contenedorBoton}>
              <Button className={styles.botonIr} onClick={onClickPago} variant="contained" color="primary" type="submit">
                {t('tracking:paginas.tracking.cicloFacturacion.avisoPago.textos.btnIniciarPago')}
              </Button>
            </section>
          </section>
        );
      case TipoPagoBoleta.PENDIENTE_PAGO:
        return (
          <section className={styles.avisoCnt}>
            <Hidden only={['md', 'lg', 'xl']}>
              <section className={styles.vencimientoMonto}>
                <Typography className={styles.infoPendiente} strong="semi-bold" variant="h1">
                  {FormatValues.money(monto)}
                </Typography>
              </section>
              <section className={styles.vencimientoContenedor}>
                {obtenerTextoAvisoPagoCCPendientePago(fechaEmision, fechaVencimiento, styles, t)}
              </section>
              <a
                className={styles.verBoletaPendiente}
                onClick={onClickVerBoleta}
                href={convertToHttps(linkPaperlessBoleta)}
                target="_blank"
                rel="noreferrer"
                download
              >
                <img src={verUltimaBoleta} alt="ultima boleta mobile" />
                <Typography className={styles.textoBoton} variant="parrafo">
                  {t('tracking:paginas.tracking.cicloFacturacion.avisoPago.textos.verBoleta')}
                </Typography>
              </a>
              <section className={styles.vencimientoBoton}>
                <Button className={styles.botonIr} onClick={onClickPago} variant="contained" color="primary" type="submit">
                  {t('tracking:paginas.tracking.cicloFacturacion.avisoPago.textos.btnIniciarPago')}
                </Button>
              </section>
            </Hidden>
            <Hidden only={['xs', 'sm']}>
              {obtenerTextoAvisoPagoCCPendientePago(fechaEmision, fechaVencimiento, styles, t)}
              <section className={styles.vencimientoMonto}>
                <Typography className={styles.label} strong="semi-bold" variant="parrafo">
                  {t('tracking:paginas.tracking.cicloFacturacion.avisoPago.textos.monto')}
                </Typography>
                {obtenerTextoAvisoPagoCC(FormatValues.money(monto), styles, t, 'semi-bold')}
              </section>
              <div className={styles.lineaSeparadora} />
              <a
                className={styles.verBoletaPendiente}
                onClick={onClickVerBoleta}
                href={convertToHttps(linkPaperlessBoleta)}
                target="_blank"
                rel="noreferrer"
                download
              >
                <img src={verUltimaBoleta} alt="ultima boleta" />
                <Typography className={styles.textoBoton} variant="parrafo">
                  {t('tracking:paginas.tracking.cicloFacturacion.avisoPago.textos.verBoleta')}
                </Typography>
              </a>
              <section className={styles.vencimientoBoton}>
                <Button className={styles.botonIr} onClick={onClickPago} variant="contained" color="primary" type="submit">
                  {t('tracking:paginas.tracking.cicloFacturacion.avisoPago.textos.btnIniciarPago')}
                </Button>
              </section>
            </Hidden>
          </section>
        );
      case null:
      case TipoPagoBoleta.PAGADO:
        return (
          <section className={styles.avisoCnt}>
            <section className={styles.pagadoIcono}>
              <BoletaEstadoIcon className={styles.iconSuccess} />
              <Typography className={styles.label} strong="semi-bold" variant="parrafo">
                {t('tracking:paginas.tracking.cicloFacturacion.avisoPago.textos.boletaPagada')}
              </Typography>
            </section>
            <Hidden only={['md', 'lg', 'xl']}>
              <section className={styles.contenedorMobile}>{obtenerTextoAvisoPagoCCPagado(fechaPago, monto, styles, t)}</section>
            </Hidden>
            <Hidden only={['xs', 'sm']}>{obtenerTextoAvisoPagoCCPagado(fechaPago, monto, styles, t)}</Hidden>
          </section>
        );
      default:
        return <></>;
    }
  };

  /**
   * Obtiene contenido a renderizar
   */
  const [renderContenido, setRenderContenido] = useState(contenidoPorTipo());

  useEffect(() => {
    setRenderContenido(contenidoPorTipo());
  }, []);

  return (
    <section data-testid="map-container" className={className}>
      {renderContenido}
    </section>
  );
};

AvisoPagoComercial.defaultProps = {
  className: '',
};

export default AvisoPagoComercial;
