import { createStyles, makeStyles, Theme } from '@material-ui/core';

const ModalNumeroClienteStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalGlobal: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      background: 'var(--blanco)',
      padding: '44px',
      borderRadius: '15px',
      boxShadow: 'var(--box-shadow)',
      transform: 'translate(-50%, -50%)',
      zIndex: 4,
      gap: '12px',
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        width: '90%',
        padding: '44px 20px 32px 20px',
      },
    },

    parrafoGlobal: {
      fontFamily: 'Neutrif Studio',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '18px',
      color: 'var(--gris-l3)',
      flex: 'none',
      alignSelf: 'stretch',
      flexGrow: 0,
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },

    contenedorCerrar: {
      position: 'fixed',
      top: 0,
      right: 0,
      padding: '2vw',
    },
    contenedorTitulo: {},
    contenedorInterno: {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
    },
    imagenBoleta: {
      width: '342px',
      height: '247px',
      [theme.breakpoints.down('sm')]: {},
    },
  })
);

export default ModalNumeroClienteStyles;
