import { createStyles, makeStyles } from '@material-ui/core';

const topHeaderStyles = makeStyles(() =>
  createStyles({
    contenedorHeader: {
      '--ancho-maximo-grid': '1090px',
      maxWidth: 'var(--ancho-maximo-grid)',
      display: 'flex',
      margin: '0 auto',
      width: '100%',
      justifyContent: 'end',
    },
    menuRoot: {
      display: 'flex',
      alignItems: 'end',
      margin: '0',
      padding: '0',
    },
    item: {
      listStyleType: 'none',
      marginRight: '2.375rem',
      cursor: 'pointer',
      fontSize: '0.875rem',
    },
    link: {
      color: 'white',
      textDecoration: 'none',
    },
  })
);

export default topHeaderStyles;
