import { Button, Hidden } from '@material-ui/core';
import { Typography } from '@saesa/ui-kit-front';
import clsx from 'clsx';
import { InfoClienteModel, Usuario } from 'models';
import { useTranslation } from 'react-i18next';
import useStyles from './InfoClienteStyles';

const InfoCliente = ({ usuario, onClickVolver, dataTestId }: { usuario: Usuario; dataTestId?: string; onClickVolver: () => void }) => {
  /**
   * Información cliente
   */
  const infoCliente: InfoClienteModel = {
    codDistribuidora: usuario.codDistribuidora,
    estadoServicio: usuario.codigoEstadoSuministro,
    comuna: usuario.comuna,
    localidad: usuario.localidad,
    numeroServicio: usuario.numeroServicio,
  };
  /**
   * Uso de estilos
   */
  const styles = useStyles();
  /**
   * Uso del i18n para traducciones
   */
  const { t } = useTranslation();

  return (
    <section
      className={clsx(styles.detalleServicioOk, styles.servicio, {
        [styles.detalleServicioFail]: !infoCliente.estadoServicio,
      })}
    >
      <section>
        <section className={styles.numeroCliente}>
          <Hidden only={['xs', 'sm']}>
            <Typography strong="bold" className={styles.tituloCampo} variant="parrafo">
              {t('componentes.detalleServicio.numeroCliente')}
            </Typography>
          </Hidden>
          <Hidden only={['md', 'lg', 'xl']}>
            <Typography strong="bold" className={styles.tituloCampo} variant="parrafo">
              {t('componentes.detalleServicio.numeroClienteMobile')}
            </Typography>
          </Hidden>
          <Typography className={styles.tituloCampo} variant="parrafo">
            {infoCliente.numeroServicio}
          </Typography>
        </section>
        <section className={styles.comuna}>
          <Typography strong="bold" className={styles.tituloCampo} variant="parrafo">
            {t('componentes.detalleServicio.comuna')}
          </Typography>
          <Typography className={styles.tituloCampo} variant="parrafo">
            {infoCliente.comuna}
          </Typography>
        </section>
        <section className={styles.localidad}>
          <Typography strong="bold" className={styles.tituloCampo} variant="parrafo">
            {t('componentes.detalleServicio.localidad')}
          </Typography>
          <Typography className={styles.tituloCampo} variant="parrafo">
            {infoCliente.localidad}
          </Typography>
        </section>
      </section>
      <Button className={styles.botonVolver} data-testid={dataTestId} onClick={onClickVolver}>
        <Hidden only={['xs', 'sm']}>
          <Typography strong="bold" className={styles.editar} variant="label">
            {t('componentes.detalleServicio.cambiarNumero')}
          </Typography>
        </Hidden>
        <Hidden only={['md', 'lg', 'xl']}>
          <Typography strong="bold" className={styles.editar} variant="label">
            {t('componentes.detalleServicio.cambiarNumeroMobile')}
          </Typography>
        </Hidden>
      </Button>
    </section>
  );
};

InfoCliente.defaultProps = {
  dataTestId: '',
};

export default InfoCliente;
