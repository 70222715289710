class FormatValues {
  static toLocaleNumber(amount: string | number) {
    return Number(amount).toLocaleString('es-CL');
  }

  /**
   * Función que formatea un valor monetario.
   *
   * Ejemplo Modo de uso
   * const montoPagoFormateado = FormatValues.money(montoPago);
   *
   * @param amount Cantidad de monto en string
   * @returns Valor aleman formateado en comas como separador decimal y puntos miles
   */
  static money(amount: string | number) {
    return amount !== '' ? `$ ${this.toLocaleNumber(amount)}` : '';
  }
}

export default FormatValues;
