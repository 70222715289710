import { PagesItem } from 'components';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { LISTADO_TICKETS, POST_VENTA } from '../../navigation/constants';
import Routes from '../navigation/RouterConfig';

const RootPostVenta = ({ path }: { path: string }) => {
  /**
   * history
   */
  const history = useHistory();
  const location = useLocation();
  const esDetalle = String(location.pathname).includes('detalle');
  /**
   * Uso del i18n para traducciones
   */
  const { t } = useTranslation();
  /**
   * Accion boton que retrocede a paso 1
   */
  const onClickVolver = () => {
    if (esDetalle) {
      history.push(LISTADO_TICKETS);
    } else {
      history.push(POST_VENTA);
    }
  };

  return (
    <PagesItem
      titulo={esDetalle ? t('tracking:paginas.tracking.postventa.detalle.tituloDetalle') : t('tracking:paginas.tracking.postventa.titulo')}
      path={path}
      textoVolver={esDetalle ? t('tracking:paginas.tracking.postventa.detalle.volver') : t('tracking:paginas.tracking.postventa.volver')}
      onClickVolver={onClickVolver}
      dataTestId="pagina-principal"
    >
      <Routes />
    </PagesItem>
  );
};

export default RootPostVenta;
