import AnalyticsService from 'services/AnalyticsService';
import { gtmEvents } from './gtmEvents';

/**
 * Metodo que envia un evento tag al presionar btn de ingreso con cuenta apple en registro.
 */
export const tagFacturacionPantallaIncio = (data: any) => {
  AnalyticsService.enviarTag(gtmEvents.tracking.facturacion.pantallaIncio, data);
};

/**
 * Metodo que envia un evento tag al presionar btn validar numero.
 */
export const tagFacturacionValidarNumero = (data: any) => {
  AnalyticsService.enviarTag(gtmEvents.tracking.facturacion.btnValidarNumero, data);
};

/**
 * Metodo que envia un evento tag al presionar btn validar numero.
 */
export const tagFacturacionServicioOK = (data: any) => {
  AnalyticsService.enviarTag(gtmEvents.tracking.facturacion.servicioOK, data);
};

/**
 * Metodo que envia un evento tag al presionar btn validar numero.
 */
export const tagFacturacionBotonPago = (data: any) => {
  AnalyticsService.enviarTag(gtmEvents.tracking.facturacion.botonPago, data);
};

/**
 * Metodo que envia un evento tag al presionar btn ver boleta.
 */
export const tagFacturacionVerBoleta = (data: any) => {
  AnalyticsService.enviarTag(gtmEvents.tracking.facturacion.verBoleta, data);
};

/**
 * Metodo que envia un evento tag al presionar btn de ingreso con cuenta apple en registro.
 */
export const tagPostVentaPantallaIncio = (data: any) => {
  AnalyticsService.enviarTag(gtmEvents.tracking.postVenta.pantallaIncio, data);
};

/**
 * Metodo que envia un evento tag al presionar btn validar numero.
 */
export const tagPostVentaValidarNumero = (data: any) => {
  AnalyticsService.enviarTag(gtmEvents.tracking.postVenta.btnValidarNumero, data);
};

/**
 * Metodo que envia un evento tag al presionar btn validar numero.
 */
export const tagPostVentaPantallaResumen = (data: any) => {
  AnalyticsService.enviarTag(gtmEvents.tracking.postVenta.pantallaResumenTicket, data);
};

/**
 * Metodo que envia un evento tag al presionar btn validar numero.
 */
export const tagPostVentaPantallaDetalle = (data: any) => {
  AnalyticsService.enviarTag(gtmEvents.tracking.postVenta.pantallaDetalleTicket, data);
};

/**
 * Metodo que envia un evento tag al presionar btn validar numero.
 */
export const tagCorteValidarNumeroFail = (data: any) => {
  AnalyticsService.enviarTag(gtmEvents.tracking.corte.btnValidarNumeroFail, data);
};

/**
 * Metodo que envia un evento tag al presionar btn de ingreso con cuenta apple en registro.
 */
export const tagCortePantallaIncio = (data: any) => {
  AnalyticsService.enviarTag(gtmEvents.tracking.corte.pantallaIncio, data);
};

/**
 * Metodo que envia un evento tag al presionar btn validar numero.
 */
export const tagCorteValidarNumero = (data: any) => {
  AnalyticsService.enviarTag(gtmEvents.tracking.corte.btnValidarNumero, data);
};

/**
 * Metodo que envia un evento tag al presionar btn validar numero.
 */
export const tagCorteValidarNumeroOK = (data: any) => {
  AnalyticsService.enviarTag(gtmEvents.tracking.corte.btnValidarNumeroOK, data);
};

/**
 * Metodo que envia un evento tag al presionar btn validar numero.
 */
export const tagCortePantallaCNP = (data: any) => {
  AnalyticsService.enviarTag(gtmEvents.tracking.corte.pantallaCorteNoProgramado, data);
};

/**
 * Metodo que envia un evento tag al presionar btn validar numero.
 */
export const tagCortePantallaCP = (data: any) => {
  AnalyticsService.enviarTag(gtmEvents.tracking.corte.pantallaCorteProgramado, data);
};

/**
 * Metodo que envia un evento tag al presionar btn validar numero.
 */
export const tagCorteServicioOK = (data: any) => {
  AnalyticsService.enviarTag(gtmEvents.tracking.corte.servicioOK, data);
};
