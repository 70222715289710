export enum TipoEtapaTrackingEnum {
  DEFAULT = '',
  /** Toma lectura */
  EN_PREPARACION = 'EN_PREPARACION',
  EN_TOMA_LECTURA = 'EN_TOMA_LECTURA',
  LECTURA_EXITOSA = 'LECTURA_EXITOSA',
  LECTURA_NO_EXITOSA = 'LECTURA_NO_EXITOSA',
  NOTIFICACION_BOLETA = 'NOTIFICACION_BOLETA',
  BOLETA_ENTREGADA = 'BOLETA_ENTREGADA',
  BOLETA_NO_ENTREGADA = 'BOLETA_NO_ENTREGADA',
}
