import { createStyles, makeStyles, Theme } from '@material-ui/core';

const headerStyles = makeStyles((theme: Theme) =>
  createStyles({
    logoImg: {
      height: 'inherit',
    },
    logo: {
      width: '160px',
      height: '60px',
      display: 'flex',
      alignItems: 'center',
      marginRight: 'auto',
      background: 'transparent',
      border: 'none',
      cursor: 'pointer',
      [theme.breakpoints.down('sm')]: {
        width: '110px',
        height: '36px',
      },
    },
    menuHeader: {
      display: 'flex',
      alignItems: 'center',
      margin: '0',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    iconAbrirMenu: {
      display: 'block',
      stroke: 'var(--primario-azul-oscuro)',
    },
    iconCerrarMenu: {
      display: 'none',
      stroke: 'var(--primario-azul-oscuro)',
    },
    mostrarElemento: {
      display: 'block',
    },
    esconderElemento: {
      display: 'none',
      position: 'absolute',
      visibility: 'hidden',
    },
    item: {
      listStyleType: 'none',
      marginRight: '2.375rem',
      cursor: 'pointer',
      fontSize: '0.875rem',
      '&:last-child': {
        marginRight: '0',
      },
    },
    link: {
      color: 'var(--primario-azul-oscuro)',
      textDecoration: 'none',
      fontSize: '1.125rem',
      [theme.breakpoints.down('sm')]: {},
    },
    outlined: {
      color: 'var(--primario-morado)',
      backgroundColor: 'var(--blanco)',
      border: '2px solid var(--primario-morado)',
      borderWidth: '2px',
      '&:hover': {
        color: 'var(--primario-morado)',
        border: '2px solid var(--primario-morado)',
        backgroundColor: 'var(--hover-secundary)',
      },
    },
    menuItemActive: {
      display: 'none',
    },
  })
);

export default headerStyles;
