import { createStyles, makeStyles, Theme } from '@material-ui/core';

const infoClienteStyles = makeStyles((theme: Theme) =>
  createStyles({
    servicio: {
      display: 'flex',
      background: 'hsla(var(--valor-primario-morado), 0.05)',
      borderRadius: '0.625rem',
      padding: '1.25rem 1rem',
      [theme.breakpoints.down('sm')]: {
        minWidth: 'initial',
        padding: '1.25rem .625rem',
      },
    },
    detalleServicioOk: {
      display: 'flex',
      maxWidth: '422px',
      margin: '0 auto 4rem auto',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '422px',
        minWidth: 'auto',
        margin: '0 auto 2rem auto',
      },
    },
    detalleServicioFail: {
      padding: '1.25rem 1rem',
      [theme.breakpoints.down('sm')]: {
        padding: '1.25rem .625rem',
        maxWidth: '422px',
        minWidth: 'auto',
      },
    },
    headerServicio: {
      display: 'flex',
      marginLeft: '1rem',
      marginRight: '15rem',
    },
    bodyServicio: {
      margin: '1rem 0 0 1rem',
    },
    numeroCliente: {
      display: 'flex',
      marginBottom: '0.375rem',
    },
    calle: {
      display: 'flex',
    },
    comuna: {
      display: 'flex',
      marginBottom: '0.4375rem',
    },
    localidad: {
      display: 'flex',
    },
    estado: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        width: '200%',
      },
    },
    estadoActivo: {
      color: 'var(--success)',
      marginLeft: '0.25rem',
    },
    estadoInactivo: {
      color: 'var(--rojo-error)',
      marginLeft: '0.25rem',
    },
    estadoTransicion: {
      color: 'var(--amarillo-l44)',
      marginLeft: '0.25rem',
    },
    tituloCampo: {
      marginLeft: '0.25rem',
    },
    campo: {
      marginLeft: '0.25rem',
    },
    botonVolver: {
      padding: '0',
      height: '10px',
      minWidth: '100px;',
    },
    editar: {
      textAlign: 'right',
      color: 'var(--primario-morado)',
    },
  })
);

export default infoClienteStyles;
