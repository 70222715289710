import { createStyles, makeStyles, Theme } from '@material-ui/core';

const sidemenuStyles = makeStyles((theme: Theme) =>
  createStyles({
    sidemenu: {
      position: 'relative',
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'center',
      transition: 'var(--transition) all',
      background: 'var(--primario-azul-oscuro)',
      zIndex: 3,
      [theme.breakpoints.down('sm')]: {
        position: 'fixed',
        alignItems: 'flex-start',
        left: '50%',
        top: '82px',
        width: '95%',
        overflow: 'auto',
        transform: 'translateX(100%)',
      },
    },
    itemCnt: {
      padding: '0 1rem',
      margin: '0',
      listStyle: 'none',
    },
    sidemenuOpen: {
      transform: 'translateX(-50%)',
    },
    logo: {
      width: '60%',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    logoImg: {
      width: '100%',
      height: '38px',
    },
    menu: {
      marginTop: '6rem',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        marginTop: '0',
        padding: '1.75rem 0',
      },
    },
    sidemenuItemCnt: {
      padding: '0.75rem 0',
    },
    sidemenuItem: {
      marginBottom: '1.25rem',
      color: 'var(--blanco)',
      listStyle: 'none',
      textDecoration: 'none',
      fontSize: '.875rem',
    },
    menuUsuario: {
      background: 'var(--gray03)',
      width: '100%',
      padding: '1.75rem 0',
      flexGrow: 1,
    },
    perfilUsuario: {
      padding: '0 2rem',
    },
    divisorCnt: {
      display: 'block',
      width: '100%',
      padding: '0 2rem',
    },
    divisor: {
      display: 'block',
      width: '100%',
      borderBottom: '1px solid var(--saesa-sky)',
    },
  })
);

export default sidemenuStyles;
