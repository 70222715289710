import { TipoEtapaTrackingEnum, TipoPagoBoleta } from 'config';
import { actualizarTrackingCicloComercialPendienteDePago } from 'store';

/**
 * Función que cambia el estado a notificación debido a fallas en el servicio de facturación
 * @param responseFacturacion respuesta del servicio de facturación
 * @param responseEstados respuesta del servicio de estado de facturación
 * @param dispatch hook de react para disparar el estado
 */
export const actualizarEstadoNotificacion = (responseFacturacion: any, responseEstados: any, dispatch: any) => {
  if (
    responseFacturacion.cobroActual.estadoPago === TipoPagoBoleta.PENDIENTE_PAGO &&
    responseEstados.estado !== TipoEtapaTrackingEnum.BOLETA_ENTREGADA &&
    responseEstados.estado !== TipoEtapaTrackingEnum.BOLETA_NO_ENTREGADA
  ) {
    dispatch(actualizarTrackingCicloComercialPendienteDePago(TipoEtapaTrackingEnum.NOTIFICACION_BOLETA));
  }
};
