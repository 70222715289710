export const gtmEvents = {
  tracking: {
    facturacion: {
      pantallaIncio: {
        evento: 'tracking_ciclo_comercial_pantalla_inicio',
        nombre: 'Pantalla inicio',
        categoria: 'tracking_ciclo_comercial',
      },
      btnValidarNumero: {
        evento: 'tracking_ciclo_comercial_boton_validar',
        nombre: 'Botón validar',
        categoria: 'tracking_ciclo_comercial',
      },
      servicioOK: {
        evento: 'tracking_ciclo_comercial_llamada_ok',
        nombre: 'Servicio OK',
        categoria: 'tracking_ciclo_comercial',
      },
      botonPago: {
        evento: 'tracking_ciclo_comercial_boton_pago',
        nombre: 'Botón pago',
        categoria: 'tracking_ciclo_comercial',
      },
      verBoleta: {
        evento: 'tracking_ciclo_comercial_ver_boleta',
        nombre: 'Ver boleta',
        categoria: 'tracking_ciclo_comercial',
      },
    },
    postVenta: {
      pantallaIncio: {
        evento: 'tracking_post_venta_pantalla_inicio',
        nombre: 'Pantalla inicio',
        categoria: 'tracking_post_venta',
      },
      btnValidarNumero: {
        evento: 'tracking_post_venta_boton_validar',
        nombre: 'Botón validar',
        categoria: 'tracking_post_venta',
      },
      pantallaResumenTicket: {
        evento: 'tracking_post_venta_resumen_ticket',
        nombre: 'Pantalla resumen ticket',
        categoria: 'tracking_post_venta',
      },
      pantallaDetalleTicket: {
        evento: 'tracking_post_venta_detalle_ticket',
        nombre: 'Pantalla detalle ticket',
        categoria: 'tracking_post_venta',
      },
    },
    corte: {
      pantallaIncio: {
        evento: 'tracking_corte_pantalla_inicio',
        nombre: 'Pantalla inicio',
        categoria: 'tracking_corte',
      },
      btnValidarNumero: {
        evento: 'tracking_corte_boton_validar',
        nombre: 'Botón validar',
        categoria: 'tracking_corte',
      },
      btnValidarNumeroFail: {
        evento: 'tracking_corte_validar_numero_fail',
        nombre: 'Botón validar - Fallido',
        categoria: 'tracking_corte',
      },
      btnValidarNumeroOK: {
        evento: 'tracking_corte_validar_numero_ok',
        nombre: 'Botón validar - Éxito',
        categoria: 'tracking_corte',
      },
      pantallaCorteNoProgramado: {
        evento: 'tracking_corte_no_programado',
        nombre: 'Tracking CNP',
        categoria: 'tracking_corte',
      },
      pantallaCorteProgramado: {
        evento: 'tracking_corte_programado',
        nombre: 'Corte programado',
        categoria: 'tracking_corte',
      },
      servicioOK: {
        evento: 'tracking_corte_servicio_ok',
        nombre: 'Servicio OK',
        categoria: 'tracking_corte',
      },
    },
  },
};
