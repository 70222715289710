import { endOfDay, format } from 'date-fns';

const LARGO_FORMATO_FECHA = 10;
const LARGO_FORMATO_FECHA_ISO = 19;
/**
 *
 * @param time Tiempo expresado en string
 * @returns Reemplaza guiones - por slash /
 */
const withSlash = (time: string) => (time ? time.replace(/-/g, '/') : '');
/**
 *
 * @param time Tiempo expresado en string
 * @returns Tiempo en String formateado de YYYY-MM-DD a DD/MM/YYYY
 */
const dateToString = (time: string) => {
  const timeFormatter = time
    ? `${new Date(withSlash(time)).toLocaleDateString('es-CL', {
        hour12: false,
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      })}`
    : '';
  return timeFormatter.replace(/-/g, '/');
};

/**
 * Metodo que se encarga de validar si un dato existe.
 * @param dato valor del dato a validar.
 * @returns true si es valido, false si es invalido.
 */
export const esDatoValido = (dato: any) => dato && dato !== undefined && dato !== null;

/**
 * Metodo que se encarga de validar el estado PAGADO. Cobro actual responde todos sus atriburos en null si la boleta esta cancelada.
 * @param dato objeto a valida
 * @returns true si es valido, false si es invalido.
 */
export const esBoletaPagada = (dato: any) => Object.values(dato).every(x => x === null || x === '');

/**
 * Recibe una fecha de tipo string y la transforma en el formato dd/MM/yyyy
 * @param dato fecha enviada
 * @returns fecha formateada
 */
export const formatoFecha = (dato: string) => {
  if (!esDatoValido(dato)) {
    return '';
  }
  if (dato.length === LARGO_FORMATO_FECHA) {
    return dato && dateToString(dato);
  }
  if (dato.length === LARGO_FORMATO_FECHA_ISO) {
    return dato && format(endOfDay(new Date(dato)), 'dd/MM/yyyy');
  }
  return '';
};

/**
 * Recibe una fecha de tipo string y obtiene la hora
 * @param dato fecha enviada
 * @returns fecha formateada
 */
export const obtenerHora = (dato: string) => {
  if (dato) {
    return `${dato.split('T')[1].substring(0, 5)} hrs.`;
  }
  return '';
};

/**
 * Recibe una fecha de tipo string y la transforma en el formato dd/MM/yyyy - HH:MM
 * @param dato fecha enviada
 * @returns fecha formateada
 */
export const formatoFechaConHora = (dato: string) => dato && `${formatoFecha(dato)}-${obtenerHora(dato)}`;

/**
 * Obtiene sin determinar si no viene fecha
 * @param dato fecha enviada
 * @param t componente traduccion
 * @returns retorna la fecha o el sin determinar
 */
export const fechaSinDeterminar = (dato: string, t: any) => dato || t('tracking:paginas.tracking.corte.avisoPago.textos.sinDeterminar');
