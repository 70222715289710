import ReactDOM from 'react-dom';
import { Typography, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import IconoCerrar from 'components/BotonCerrar/IconoCerrar';
import { irAUrl, urlTitularidad } from 'utils';
import { DANGER_ICONO } from 'assets/images';
import ModalGlobalStyles from 'utils/styles/ModalGlobalStyles';
import ModalRutNoEncontradoStyles from './ModalRutNoEncontradoStyles';

const ModalRutNoEncontrado = ({ cerrarModal }: { cerrarModal: () => void }) => {
  /**
   * Selecciona el elemento modal
   */
  const element = document.getElementById('modal')!;

  /**
   * Componente styles
   */
  const styles = ModalRutNoEncontradoStyles();

  /**
   * Componente styles de modal global
   */
  const stylesGlobal = ModalGlobalStyles();

  /**
   * Componente de traduccion i18n
   */
  const { t } = useTranslation();

  return (
    element &&
    ReactDOM.createPortal(
      <div className={stylesGlobal.backdrop}>
        <section className={`${stylesGlobal.modalGlobal} ${styles.modalGlobal}`}>
          <div className={stylesGlobal.contenedorIcono}>
            <img src={DANGER_ICONO} alt="Icono" />
          </div>
          <div className={stylesGlobal.contenedorTitulo}>
            <Typography className={`${stylesGlobal.tituloTextos} ${styles.tituloTexto}`}>
              {t('tracking:paginas.tracking.cicloFacturacion.busquedaPorRut.modalRutNoEncontrado.titulo')}
            </Typography>
          </div>

          <div className={styles.contenedorInterno}>
            <Typography className={`${stylesGlobal.parrafoGlobal} ${stylesGlobal.textoAlinearMobile}`}>
              {t('tracking:paginas.tracking.cicloFacturacion.busquedaPorRut.modalRutNoEncontrado.subTitulo')}
            </Typography>

            <Typography className={stylesGlobal.parrafosConLinks}>
              {t('tracking:paginas.tracking.cicloFacturacion.busquedaPorRut.modalRutNoEncontrado.itemTextos.textoUno')}
              <span
                onClick={() => irAUrl(urlTitularidad)}
                onKeyDown={e => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    irAUrl(urlTitularidad);
                  }
                }}
                role="button"
                tabIndex={0}
                className={stylesGlobal.textoLink}
                data-testid="urlTitularidad"
              >
                {t('tracking:paginas.tracking.cicloFacturacion.busquedaPorRut.modalRutNoEncontrado.itemTextos.textoLink')}
              </span>
              {t('tracking:paginas.tracking.cicloFacturacion.busquedaPorRut.modalRutNoEncontrado.itemTextos.textoTres')}
            </Typography>
          </div>

          <div className={stylesGlobal.contenedorBotones}>
            <Button
              className={`${stylesGlobal.boton} ${styles.cambiarTamanioBoton}`}
              type="button"
              variant="outlined"
              color="primary"
              onClick={cerrarModal}
              data-testid="cerrarModalBoton"
            >
              {t('tracking:paginas.tracking.cicloFacturacion.busquedaPorRut.modalRutNoEncontrado.botonCerrar')}
            </Button>
          </div>
          <IconoCerrar cerrarModal={cerrarModal} />
        </section>
      </div>,
      element
    )
  );
};

export default ModalRutNoEncontrado;
