import ReactDOM from 'react-dom';
import { useState } from 'react';
import { Typography, IconButton, Button, Radio, FormControlLabel } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CLOSE_CIRCLE } from 'assets/images';
import ItemsBusquedaRut from 'components/ItemsBusquedaRut/ItemsBusquedaRut';
import ModalGlobalStyles from 'utils/styles/ModalGlobalStyles';
import TrackingService from 'services/TrackingService';
import {
  actualizarEstadoModalListadoServicios,
  actualizarFacturacion,
  actualizarLogo,
  actualizarTrackingCicloComercial,
  actualizarUsuario,
} from 'store';
import { CICLO_FACTURACION, DETALLE, ERROR_N_SERVICIO_NO_EXISTE, ERROR_N_SERVICIO_SIN_INSTANCIA } from 'navigation';
import { actualizarEstadoNotificacion } from 'utils/actualizarEstadoNotificacion';
import { obtenerLogo } from 'utils';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { TipoEtapaTrackingEnum } from 'config';
import { useHistory } from 'react-router-dom';
import { tagFacturacionServicioOK, tagFacturacionValidarNumero } from 'utils/gtmEventsUtils';
import ModalSeleccionarNumeroClienteStyles from './ModalSeleccionarNumeroClienteStyles';

const ModalSeleccionarNumeroCliente = ({
  cerrarModal,
  servicios,
}: {
  cerrarModal: () => void;
  servicios: { numeroServicio: string; comuna: string; direccion: string; distribuidora: string }[];
}) => {
  /**
   * Elemento seleccionado por el id que es el modal
   */
  const element = document.getElementById('modal')!;

  /**
   * Componente styles
   */
  const styles = ModalSeleccionarNumeroClienteStyles();

  /**
   * Componente styles global
   */
  const stylesGlobal = ModalGlobalStyles();

  /**
   * Componente de traduccion i18n
   */
  const { t } = useTranslation();

  /**
   * Funcion dispatch set redux
   */
  const dispatch = useDispatch();

  /**
   * Componente de pildora
   */
  const { enqueueSnackbar } = useSnackbar();

  /**
   * Componente history gestor ruteos
   */
  const history = useHistory();

  /**
   * Estado para el servicio seleccionado
   */
  const [servicioSeleccionado, setServicioSeleccionado] = useState<string | null>(null);

  /**
   * Función verficadora de que el estado del ciclo sea correcto
   * @param estadoCiclo tipo de estado en el ciclo de tracking
   */
  const flujoTracking = (estadoCiclo: string) => {
    if (estadoCiclo in TipoEtapaTrackingEnum) {
      history.push(`${CICLO_FACTURACION}/${DETALLE}`);
    } else {
      enqueueSnackbar(t('errors.generic'), { variant: 'error' });
    }
  };

  /**
   * Función encargada de validar el logo a mostar segun la distribuidora
   * @param codDistribuidora codigo de la distribuidoras del grupo Saesa
   */
  const validarLogo = (codDistribuidora: string) => {
    const actualizarLogoBody = {
      urlLogo: obtenerLogo(codDistribuidora),
    };
    dispatch(actualizarLogo(actualizarLogoBody));
  };

  /**
   * Función disparadora del evento click al seleccionar el servicio
   */
  const onSubmit = async () => {
    if (!servicioSeleccionado) {
      enqueueSnackbar(t('errors.noServicioSeleccionado'), { variant: 'error' });
      return;
    }

    try {
      // Servicio InfoCliente de tracking.
      const responseInfoCliente = await TrackingService.obtenerInfoCliente(servicioSeleccionado);
      validarLogo(responseInfoCliente.codDistribuidora);
      dispatch(actualizarUsuario({ ...responseInfoCliente, numeroServicio: servicioSeleccionado }));

      // Obtención de instancia de tracking por etiqueta  y por ID.
      const responseEtiqueta = await TrackingService.obtenerTrackingEtiqueta(servicioSeleccionado);
      const responseEstados = await TrackingService.obtenerEstadosTrackingPorId(responseEtiqueta.trackings[0].id);
      dispatch(actualizarTrackingCicloComercial({ ...responseEstados }));

      // Obtención de información de facturación.
      const responseFacturacion = await TrackingService.obtenerFacturacionTracking(servicioSeleccionado);
      dispatch(actualizarFacturacion({ ...responseFacturacion }));
      actualizarEstadoNotificacion(responseFacturacion, responseEstados, dispatch);

      flujoTracking(responseEstados.estado);

      tagFacturacionServicioOK({ numServicio: servicioSeleccionado });
    } catch (error: any) {
      dispatch(actualizarEstadoModalListadoServicios(false));

      if (error.message === ERROR_N_SERVICIO_NO_EXISTE) {
        enqueueSnackbar(t('errors.noExisteNumero'), { variant: 'error' });
      } else if (error.message === ERROR_N_SERVICIO_SIN_INSTANCIA) {
        enqueueSnackbar(t('errors.sinInstancia'), { variant: 'error' });
      } else {
        enqueueSnackbar(t('errors.generic'), { variant: 'error' });
      }
    } finally {
      tagFacturacionValidarNumero({ numServicio: servicioSeleccionado });
    }
    setServicioSeleccionado(null);
  };

  return (
    element &&
    ReactDOM.createPortal(
      <div className={stylesGlobal.backdrop}>
        <section className={`${styles.modalGlobal}`}>
          <div className={styles.contenedorTitulo}>
            <Typography className={stylesGlobal.tituloTextos}>
              {t('tracking:paginas.tracking.cicloFacturacion.busquedaPorRut.modalSeleccionarNumero.titulo')}
            </Typography>
          </div>

          <div className={styles.contenedorInterno}>
            {servicios.map(servicio => (
              <div key={servicio.numeroServicio} className={styles.contenedorButtonTexto}>
                <FormControlLabel
                  className={styles.radioButton}
                  control={
                    <Radio
                      className={styles.labelRadioButton}
                      checked={servicioSeleccionado === servicio.numeroServicio}
                      onChange={() => setServicioSeleccionado(servicio.numeroServicio)}
                      value={servicio.numeroServicio}
                      color="primary"
                    />
                  }
                  label=""
                />
                <ItemsBusquedaRut numeroCliente={servicio.numeroServicio} comuna={servicio.comuna} direccion={servicio.direccion} />
              </div>
            ))}
          </div>

          <div className={`${stylesGlobal.contenedorBotones}`}>
            <Button
              className={stylesGlobal.boton}
              type="button"
              variant="contained"
              color="primary"
              disabled={servicioSeleccionado === null}
              onClick={onSubmit}
              data-testid="cerrarModalBoton"
            >
              {t('tracking:paginas.tracking.cicloFacturacion.busquedaPorRut.modalSeleccionarNumero.textoConfirmar')}
            </Button>
          </div>

          <div className={styles.contenedorCerrar}>
            <IconButton aria-label="cerrar" onClick={cerrarModal} data-testid="cerrarModal">
              <img src={CLOSE_CIRCLE} alt="cerrar" />
            </IconButton>
          </div>
        </section>
      </div>,
      element
    )
  );
};

export default ModalSeleccionarNumeroCliente;
