import axios from 'axios';
import { HttpStatusCodeEnum } from './HttpStatusCodeEnum';

const ocultarLoading = () => {
  let contador: string | number | null = sessionStorage.getItem('loading');
  contador = parseInt(contador!, 10);
  if (contador > 0) {
    contador = contador > 0 ? contador - 1 : 0;
    sessionStorage.setItem('loading', contador.toString());
  }

  if (contador === 0) {
    const loading: any = document.querySelectorAll('#loading');
    loading.forEach((item: any) => item.classList.remove('backdrop--show'));
  }
};

const ErrorInterceptor = (t: any) => {
  axios.interceptors.response.use(
    response => {
      ocultarLoading();
      return response;
    },
    error => {
      ocultarLoading();
      const ERROR_MSG = t('errors.generic');
      if (error.response?.data) {
        if (error.response?.data?.message) {
          error.response.data.mensaje = error.response.data?.message || ERROR_MSG;
        }
      } else {
        error.response = { ...error.response, data: { mensaje: ERROR_MSG } };
      }
      if (error.status === HttpStatusCodeEnum.INTERNAL_SERVER_ERROR) {
        error.response.data.mensaje = ERROR_MSG;
      }
      return Promise.reject(error);
    }
  );
};

export default ErrorInterceptor;
