const Telefono = ({ className }: { className?: string }) => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.261 13.7236L15.027 11.9076C14.7836 11.8038 14.5132 11.7816 14.2561 11.8444C13.9991 11.9071 13.7692 12.0514 13.601 12.2556L11.878 14.3606C9.16849 13.0277 6.97646 10.8353 5.64401 8.12561L7.75001 6.40261C7.95436 6.2347 8.09889 6.00516 8.16197 5.74831C8.22506 5.49146 8.20332 5.22108 8.10001 4.97761L6.28101 0.738608C6.16735 0.475497 5.96496 0.260615 5.70912 0.131417C5.45328 0.00221792 5.16022 -0.0331037 4.88101 0.0316083L0.945006 0.942608C0.676006 1.00355 0.435805 1.15434 0.264015 1.37012C0.0922243 1.58591 -0.000897299 1.85379 6.51764e-06 2.12961C0.000137795 4.47619 0.462511 6.79976 1.36072 8.96763C2.25893 11.1355 3.57538 13.1052 5.2349 14.7643C6.89441 16.4233 8.86448 17.7392 11.0326 18.6368C13.2007 19.5344 15.5244 19.9961 17.871 19.9956C18.1479 19.9962 18.4167 19.9025 18.6334 19.7301C18.85 19.5576 19.0015 19.3166 19.063 19.0466L19.969 15.1126C20.0318 14.8348 19.9954 14.5438 19.8661 14.2901C19.7368 14.0364 19.5227 13.836 19.261 13.7236ZM17.847 18.7506C13.4476 18.7456 9.22992 16.9956 6.11918 13.8847C3.00845 10.7738 1.25877 6.55597 1.25401 2.15661L5.14501 1.25661L6.94501 5.45161L4.10001 7.77761C5.6313 11.4535 8.55313 14.3753 12.229 15.9066L14.553 13.0626L18.748 14.8626L17.847 18.7506Z"
      fill="white"
    />
  </svg>
);

Telefono.defaultProps = {
  className: '',
};
export default Telefono;
