import { Hidden } from '@material-ui/core';
import clsx from 'clsx';
import { obtenerNavMenu } from 'helper/tracking.helper';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import useStyles from './SidemenuStyles';

const Sidemenu = ({ className }: { className?: string }) => {
  const styles = useStyles();
  /**
   * translate
   */
  const { t } = useTranslation();

  const menuAbierto = useSelector((state: RootState) => state.tracking.menuAbierto);

  return (
    <aside
      className={clsx(className, styles.sidemenu, {
        [styles.sidemenuOpen]: menuAbierto,
      })}
    >
      <Hidden only={['md', 'lg', 'xl']}>
        <div className={styles.divisorCnt}>
          <div className={styles.divisor} />
        </div>
        <ul className={styles.itemCnt}>
          {obtenerNavMenu(t).map((item: any) => (
            <li key={item.id} className={styles.sidemenuItemCnt}>
              <a className={styles.sidemenuItem} href={item.url} target="_blank" rel="noopener noreferrer">
                {item.option}
              </a>
            </li>
          ))}
        </ul>
      </Hidden>
    </aside>
  );
};

Sidemenu.defaultProps = {
  className: '',
};

export default Sidemenu;
