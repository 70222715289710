/**
 * URL de tu consumo si importa
 */
export const tuConsumoSiImporta = 'https://web.gruposaesa.cl/tu-consumo-si-importa';

/**
 * Constante que contiene el valor de cuantos milisegundos existen por cada minuto
 */
export const milisegundosPorMinuto = 60000;

/**
 * Constante que contiene la url de cambio de titularidad
 */
export const urlTitularidad = 'https://web.gruposaesa.cl/web/saesa/solicitud-cambio-de-nombre';
