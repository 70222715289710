import { createStyles, makeStyles, Theme } from '@material-ui/core';

const SubTabsStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabContainer: {
      width: 'auto',
      display: 'flex',
      padding: '0.001rem',
      [theme.breakpoints.down('sm')]: {
        padding: '0.001rem',
        paddingBottom: '4px',
      },
    },
    tab: {
      padding: '5px 20px 5px',
      display: 'flex',
      background: 'white',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '0 6px',
      border: '2px solid var(--gris-l83) !important',
      borderRadius: '16px',
      overflow: 'auto',
      scrollSnapType: 'x mandatory',
      cursor: 'pointer',
      color: 'var(--gris-l3)',
      transition: '.5s ease all',
      fontWeight: 400,
      height: '32px',
    },
    tabSeleccionada: {
      background: 'var(--secundario-azul-marino)',
      color: 'var(--blanco)',
      fontWeight: 500,
      border: '2px solid var(--secundario-azul-marino) !important',
    },
    textoTab: {
      fontFamily: 'Neutrif Studio',
      fontStyle: 'normal',
      fontSize: '14px',
      lineHeight: '18px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
      },
    },
  })
);

export default SubTabsStyles;
