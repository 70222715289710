import { obtenerNavMenu } from 'helper/tracking.helper';
import { useTranslation } from 'react-i18next';
import useStyles from './TopHeaderStyles';

const TopHeader = ({ className }: { className: string }) => {
  /**
   * translate
   */
  const { t } = useTranslation();

  const styles = useStyles();

  return (
    <section className={className}>
      <section className={styles.contenedorHeader}>
        <ul className={styles.menuRoot}>
          {obtenerNavMenu(t)
            .filter((item: any, index: number) => index < 6)
            .map((item: any) => (
              <li key={item.option} className={styles.item}>
                <a className={styles.link} href={item.url} target="_blank" rel="noopener noreferrer">
                  {item.option}
                </a>
              </li>
            ))}
        </ul>
      </section>
    </section>
  );
};

export default TopHeader;
