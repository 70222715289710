import { Hidden, IconButton } from '@material-ui/core';
import { CerrarIcon, MenuHamburguesaIcon, Strong } from '@saesa/ui-kit-front';
import clsx from 'clsx';
import { GRUPO_SAESA_WEB_URL } from 'navigation';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { actualizarEstadoMenuAbierto, RootState } from 'store';
import { CLIENTEHOGAR, CLIENTEMPRESA, SOMOSVECINOS } from '../../root-page/constants';
import useStyles from './HeaderStyles';

const Header = ({ className }: { className?: string }) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const logo = useSelector((state: RootState) => state.tracking.distribuidora);
  const dispatch = useDispatch();
  const menuAbierto = useSelector((state: RootState) => state.tracking.menuAbierto);

  /**
   * Arreglo que contiene menu de navegacion de menu tope del header
   */
  const menuNavegacion = [
    {
      id: 0,
      option: t('tracking:paginas.tracking.cicloFacturacion.home.menu.somosVecinos'),
      url: SOMOSVECINOS,
    },
    {
      id: 1,
      option: t('tracking:paginas.tracking.cicloFacturacion.home.menu.clienteHogar'),
      url: CLIENTEHOGAR,
    },
    {
      id: 2,
      option: t('tracking:paginas.tracking.cicloFacturacion.home.menu.clienteEmpresa'),
      url: CLIENTEMPRESA,
    },
  ];
  const abrirMenu = () => {
    dispatch(actualizarEstadoMenuAbierto({ menuAbierto: true }));
  };
  const cerrarMenu = () => {
    dispatch(actualizarEstadoMenuAbierto({ menuAbierto: false }));
  };
  return (
    <header className={className}>
      <a className={styles.logo} href={GRUPO_SAESA_WEB_URL} target="_blank" rel="noreferrer">
        <img src={logo.urlLogo} alt="" />
      </a>
      <ul className={styles.menuHeader}>
        {menuNavegacion.map(item => (
          <li key={item.id} className={styles.item}>
            <a className={styles.link} href={item.url} target="_blank" rel="noopener noreferrer">
              <Strong type="bold">{item.option}</Strong>
            </a>
          </li>
        ))}
      </ul>
      <Hidden only={['md', 'lg', 'xl']}>
        <IconButton
          aria-label="menu"
          onClick={() => {
            cerrarMenu();
          }}
          className={clsx(styles.iconCerrarMenu, {
            [styles.mostrarElemento]: menuAbierto,
          })}
          data-testid="cerrar-menu-lateral"
        >
          <CerrarIcon />
        </IconButton>
        <IconButton
          aria-label="menu"
          onClick={abrirMenu}
          className={clsx(styles.iconAbrirMenu, {
            [styles.esconderElemento]: menuAbierto,
          })}
        >
          <MenuHamburguesaIcon />
        </IconButton>
      </Hidden>
    </header>
  );
};

Header.defaultProps = {
  className: '',
};
export default Header;
