import { createStyles, makeStyles, Theme } from '@material-ui/core';

const rootStyles = makeStyles((theme: Theme) =>
  createStyles({
    tracking: {
      margin: '3rem 0 ',
      [theme.breakpoints.down('sm')]: {
        margin: '1.875rem 1.25rem 1rem 1.25rem',
      },
    },
    lineaTope: {
      width: '100%',
      height: '24px',
      position: 'relative',
      background: 'var(--primario-azul-oscuro)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    footer: {
      position: 'relative',
      bottom: '0',
      width: '100%',
    },
    logo: {
      width: '160px',
      height: '60px',
      display: 'flex',
      alignItems: 'center',
      marginRight: 'auto',
      [theme.breakpoints.down('sm')]: {
        width: '110px',
        height: '36px',
      },
    },
    root: {},
    header: {
      '--ancho-maximo-grid': '1090px',
      maxWidth: 'var(--ancho-maximo-grid)',
      display: 'flex',
      padding: '1.25rem 0',
      margin: '0 auto',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        padding: '1.25rem 1.25rem .75rem .75rem',
        alignItems: 'center',
      },
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      zIndex: 1,
      paddingBottom: '0',
      background: 'linear-gradient(180deg, var(--secundario-gris-azulado) 12%, var(--blanco) 108%)',
      '@media (min-height: 1100px)': {
        height: 'calc(100vh - 528px)',
      },
    },
  })
);

export default rootStyles;
