import { Typography } from '@saesa/ui-kit-front';
import { TipoEstadoActualTrackingCorteEnum } from 'config';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from './AvisoPagoCortesStyles';

const AvisoPagoCortes = ({
  className,
  horaInicioCorte,
  horaReposicion,
  fechaInicioCorte,
  fechaReposicion,
  motivoCorte,
  clientesAfectados,
  estadoCorte,
}: {
  className?: string;
  horaInicioCorte: string;
  horaReposicion: string;
  fechaInicioCorte: string;
  fechaReposicion: string;
  motivoCorte: string;
  clientesAfectados: string;
  estadoCorte: string;
}) => {
  /**
   * Uso del i18n para traducciones
   */
  const { t } = useTranslation();

  /**
   * Variable que maneja el uso de estilos.
   */
  const styles = useStyles();

  /**
   * Retorna contenido del sticky/avisopago
   */
  const contenido = () => (
    <>
      <section className={styles.avisoCorteCnt}>
        <section className={styles.dataCorte}>
          <div className={styles.item}>
            <Typography className={styles.label} strong="semi-bold" variant="parrafo">
              {t('tracking:paginas.tracking.corte.avisoPago.textos.inicioCorte')}
            </Typography>

            {fechaInicioCorte ? (
              <Typography className={styles.info} strong="normal" variant="parrafo">
                {`${fechaInicioCorte} - ${horaInicioCorte}`}
              </Typography>
            ) : (
              <Typography className={styles.sinInfo} strong="normal" variant="parrafo">
                {t('tracking:paginas.tracking.corte.avisoPago.textos.sinDeterminar')}
              </Typography>
            )}
          </div>
          <div className={styles.item}>
            {estadoCorte === TipoEstadoActualTrackingCorteEnum.NORMALIZED ? (
              <Typography className={styles.info} strong="semi-bold" variant="parrafo">
                {t('tracking:paginas.tracking.corte.avisoPago.textos.reposicionServicio')}
              </Typography>
            ) : (
              <Typography className={styles.label} strong="semi-bold" variant="parrafo">
                {t('tracking:paginas.tracking.corte.avisoPago.textos.reposicionEstimada')}
              </Typography>
            )}

            {fechaReposicion ? (
              <Typography className={styles.info} strong="normal" variant="parrafo">
                {`${fechaReposicion} - ${horaReposicion}`}
              </Typography>
            ) : (
              <Typography className={styles.sinInfo} strong="normal" variant="parrafo">
                {t('tracking:paginas.tracking.corte.avisoPago.textos.sinDeterminar')}
              </Typography>
            )}
          </div>
          <div className={styles.item}>
            <Typography className={styles.label} strong="semi-bold" variant="parrafo">
              {t('tracking:paginas.tracking.corte.avisoPago.textos.clientesAfectados')}
            </Typography>

            {clientesAfectados ? (
              <Typography className={styles.info} strong="normal" variant="parrafo">
                {clientesAfectados}
              </Typography>
            ) : (
              <Typography className={styles.sinInfo} strong="normal" variant="parrafo">
                {t('tracking:paginas.tracking.corte.avisoPago.textos.sinDeterminar')}
              </Typography>
            )}
          </div>
        </section>
        <div>
          <Typography className={styles.label} strong="semi-bold" variant="parrafo">
            {t('tracking:paginas.tracking.corte.avisoPago.textos.motivoCorte')}
          </Typography>

          {motivoCorte ? (
            <Typography className={styles.info} strong="normal" variant="parrafo">
              {motivoCorte}
            </Typography>
          ) : (
            <Typography className={styles.sinInfo} strong="normal" variant="parrafo">
              {t('tracking:paginas.tracking.corte.avisoPago.textos.sinDeterminar')}
            </Typography>
          )}
        </div>
      </section>
    </>
  );

  /**
   * Obtiene contenido a renderizar
   */
  const [renderContenido, setRenderContenido] = useState(contenido());

  useEffect(() => {
    setRenderContenido(contenido());
  }, []);

  return (
    <section data-testid="map-container" className={className}>
      {renderContenido}
    </section>
  );
};

AvisoPagoCortes.defaultProps = {
  className: '',
};

export default AvisoPagoCortes;
