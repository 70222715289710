import { Typography } from '@saesa/ui-kit-front';

const Redireccion = ({
  className,
  texto,
  variant,
  link,
}: {
  className: string;
  texto: string;
  variant: 'emphasis' | 'h1' | 'h2' | 'h3' | 'parrafo' | 'label' | 'labelSmall';
  link: string;
}) => (
  <a className={`link_boton ${className}`} type="button" href={link} target="_blank" rel="noreferrer">
    <Typography variant={variant}>{texto}</Typography>
  </a>
);

export default Redireccion;
