import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import ItemsBusquedaRutStyles from './ItemsBusquedaRutStyles';

const ItemsBusquedaRut = ({ numeroCliente, comuna, direccion }: { numeroCliente: string; comuna: string; direccion: string }) => {
  /**
   * Componente styles
   */
  const styles = ItemsBusquedaRutStyles();

  /**
   * Componente de traduccion i18n
   */
  const { t } = useTranslation();

  return (
    <div className={styles.contenedorGlobal}>
      <div className={styles.itemParrafos}>
        <Typography className={styles.tituloTextos}>
          {t('tracking:paginas.tracking.cicloFacturacion.busquedaPorRut.modalSeleccionarNumero.textoItems.numeroCliente')}
        </Typography>
        <Typography className={styles.parrafoGlobal}>{numeroCliente}</Typography>
      </div>
      <div className={styles.itemParrafos}>
        <Typography className={styles.tituloTextos}>
          {t('tracking:paginas.tracking.cicloFacturacion.busquedaPorRut.modalSeleccionarNumero.textoItems.comuna')}
        </Typography>
        <Typography className={styles.parrafoGlobal}>{comuna}</Typography>
      </div>
      <div className={styles.itemParrafos}>
        <Typography className={styles.tituloTextos}>
          {t('tracking:paginas.tracking.cicloFacturacion.busquedaPorRut.modalSeleccionarNumero.textoItems.direccion')}
        </Typography>
        <Typography className={styles.parrafoGlobal}>{direccion}</Typography>
      </div>
    </div>
  );
};

export default ItemsBusquedaRut;
