import { QueryParams } from 'config';
import { useTranslation } from 'react-i18next';
import { Typography, TextField, IconButton, InputAdornment } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { format } from 'rut.js';
import { ICONO_INFO_CIRCLE } from 'assets/images';
import { useEffect, useState } from 'react';
import ModalNumeroCliente from 'components/ModalNumeroCliente/ModalNumeroCliente';
import { TipoTab } from 'config/tipoTab';
import SubTabs from 'components/SubTabs/SubTabs';
import { actualizarEstadoErrorInitCicloComercial, RootState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { tagFacturacionPantallaIncio } from 'utils/gtmEventsUtils';
import { subTabsSeleccionables } from 'utils';
import ContenedorDeItemBusquedaStyles from './ContenedorDeItemBusquedaStyles';

const ContenedorDeItemBusqueda = ({ setEstadoDeCargaFacturacion, valorRut }: { setEstadoDeCargaFacturacion: any; valorRut: any }) => {
  /**
   * Componente styles
   */
  const styles = ContenedorDeItemBusquedaStyles();

  /**
   * Componente de traduccion i18n
   */
  const { t } = useTranslation();

  /**
   * Dispatch de redux.
   */
  const dispatch = useDispatch();

  /**
   * UseState que contiene el estado del modal
   */
  const [estadoModal, setEstadoModal] = useState(false);

  /**
   * Variable que contiene el tab seleccionado por el usuario
   */
  const itemSubTab = useSelector((state: RootState) => state.tracking.subTab);

  /**
   * Use state de los tabs de navegación
   */
  const tabSeleccionado = useSelector((state: RootState) => state.tracking.subTab);

  /**
   * Use state que contiene si la busqueda por rut esta activada
   */
  const busquedaPorRutAtivada = useSelector((state: RootState) => state.tracking.busquedaPorRutAtiva);

  /**
   * Constante que contiene el valor del redux del valor del estado del modal de rut no encontrado de busqueda por rut
   */
  const MODAL_RUT_NO_ENCONTRADO = useSelector((state: RootState) => state.tracking.errorRutNoEncontrado);

  /**
   * Use state de indicador de error al inicializar en ciclo comercial para limpiar input.
   */
  const errorInitCicloComercial = useSelector((state: RootState) => state.tracking.errorInitCicloComercial);

  /**
   * Use state de indicador de modal de listado de servicios encontrados por RUT se encuentra abierto.
   */
  const estadoModalListadoDeServicios = useSelector((state: RootState) => state.tracking.estadoModalListadoDeServicios);

  /**
   * UseState que controla el inicio de la pagina
   */
  const [inicioPagina, setInicioPagina] = useState(false);

  /**
   * Constante que contiene el valor de los subtabs a mostrar para el usuario
   */
  const dataBtnBusqueda = subTabsSeleccionables(busquedaPorRutAtivada, t);

  /**
   * Valores iniciales de formulario
   */
  const initialValues = {
    numServicio: '',
    rut: '',
  };

  /**
   *  Constante que contiene el limite de los caracteres
   */
  const limiteCaracteres = 8;

  /**
   * Validaciones de errores del formulario
   */
  const formik = useFormik({
    initialValues,
    // eslint-disable-next-line no-empty-function
    onSubmit: () => {},
    validationSchema: Yup.object({
      rut: Yup.string().test({
        name: 'conditional-validation',
        // eslint-disable-next-line
        test: function (rut) {
          // eslint-disable-next-line
          const { numServicio } = this.parent;

          // Validar rut solo si numServicio está vacío
          if (!numServicio || numServicio.trim() === '') {
            return (
              // eslint-disable-next-line
              Yup.string()
                .rut(t('tracking:paginas.tracking.cicloFacturacion.busquedaPorRut.itemBusquedas.rutInvalido'))
                .required(t('tracking:paginas.tracking.cicloFacturacion.busquedaPorRut.itemBusquedas.rutRequerido'))
                .isValidSync(rut) ||
              // eslint-disable-next-line
              this.createError({ message: t('tracking:paginas.tracking.cicloFacturacion.busquedaPorRut.itemBusquedas.rutInvalido') })
            );
          }

          return true;
        },
      }),
      numServicio: Yup.string().test({
        name: 'conditional-validation',
        // eslint-disable-next-line
        test: function (numServicio) {
          // eslint-disable-next-line
          const { rut } = this.parent;
          // Validar numServicio solo si rut está vacío
          if (!rut || rut.trim() === '') {
            return (
              Yup.string()
                .required(t('tracking:paginas.tracking.cicloFacturacion.busquedaPorRut.itemBusquedas.numeroCliente'))
                .isValidSync(numServicio) ||
              // eslint-disable-next-line
              this.createError({ message: t('tracking:paginas.tracking.cicloFacturacion.busquedaPorRut.itemBusquedas.numeroCliente') })
            );
          }

          return true;
        },
      }),
    }),
  });

  /**
   * Función que se encarga de realizar el evento de blur
   * @param event evento que contiene el disparador de la función
   */
  const onHandleBlur = (event: any) => {
    if (event.nativeEvent.target.value.length > 0) {
      const rut = format(event.nativeEvent.target.value);
      const newEvent = { ...event };
      formik.setFieldValue('rut', rut);
      formik.values.rut = rut;
      formik.handleBlur(newEvent);
    } else {
      formik.handleBlur(event);
    }
  };

  /**
   * Función que maneja el cambio de numero de cliente
   * @param event Evento que registrará el cambio de usuario
   */
  const handleInput = (event: any) => {
    let inputValue = event.target.value;

    // Elimina caracteres no numéricos
    inputValue = inputValue.replace(/[^0-9]/g, '');

    // Limita la longitud a 8 dígitos
    if (inputValue.length > limiteCaracteres) {
      inputValue = inputValue.slice(0, limiteCaracteres);
    }

    // Actualiza el valor del campo de entrada
    // eslint-disable-next-line no-param-reassign
    event.target.value = inputValue;

    if (event.nativeEvent.target.value.length >= 0) {
      const numServicio = event.nativeEvent.target.value;
      const newEvent = { ...event };
      formik.values.numServicio = numServicio;
      formik.handleBlur(newEvent);
    } else {
      formik.handleBlur(event);
    }
  };

  /**
   * Función que maneja el cambio de numero de cliente
   * @param event Evento que registrará el cambio de usuario
   */
  const handleInputRut = (event: any) => {
    if (event.nativeEvent.target.value.length > 0) {
      const rut = format(event.nativeEvent.target.value);
      const newEvent = { ...event };
      formik.values.rut = rut;
      formik.handleBlur(newEvent);
    } else {
      formik.handleBlur(event);
    }
  };

  /**
   * Variable que maneja query params
   */
  const query = QueryParams();
  /**
   * valor de numero de servicio obtenido por query params
   */
  const queryNumeroServicio = query.get('numeroServicio') || '';

  /**
   * Función encargada de abrir modal
   */
  const abrirModal = () => {
    setEstadoModal(true);
  };

  /**
   * Función encargada de cerrar el modal
   */
  const cerrarModal = () => {
    setEstadoModal(false);
  };

  /**
   * Corrige formateo de RUT al cambiar valor.
   *
   * @param event Evento donde viene el valor del input del RUT.
   */
  const handleChangeRUT = (event: any) => {
    // Formatea el valor antes de pasarlo al manejador de Formik
    const formattedValue = format(event.nativeEvent.target.value);

    // Pasamos el valor formateado a valorRut.formik.handleChange
    if (valorRut?.formik?.handleChange) {
      // Actualizamos el valor formateado en Formik
      const fakeEvent = {
        target: { name: 'rut', value: formattedValue },
      };
      valorRut.formik.handleChange(fakeEvent);
    }
  };

  /**
   * UseEffect usado para validar el boton de Acceder
   */
  useEffect(() => {
    const { numServicio, rut } = formik.values;
    const tieneErrores = Object.keys(formik.errors).length > 0;
    const isEmpty = numServicio === '' && rut === '';

    setEstadoDeCargaFacturacion(!(isEmpty || tieneErrores));
  }, [formik]);

  /**
   * UseEffect que inicializa los valores del formulario cada vez que el tab cambia y ya se ha inicializado la pagina
   */
  useEffect(() => {
    if (inicioPagina) {
      // Restablecer los campos tocados del formulario
      formik.setTouched({
        rut: false,
        numServicio: false,
      });

      formik.resetForm({
        values: {
          numServicio: '',
          rut: '',
        },
      });
    }
  }, [tabSeleccionado]);

  /**
   * UseEffect que inicializa los valores del formulario cada vez que se ha inicializado la pagina y existe un error de rut no encontrado
   * o en ciclo inicial de servicios.
   * dispatch de errorInitCicloComercial en clase CicloFacturacion.tsx
   * dispatch de estadoModalListadoDeServicios en clase ModalSeleccionarNumeroCliente.tsx.
   */
  useEffect(() => {
    if (inicioPagina && (MODAL_RUT_NO_ENCONTRADO || errorInitCicloComercial || !estadoModalListadoDeServicios)) {
      formik.setTouched({
        rut: false,
        numServicio: false,
      });

      formik.resetForm({
        values: {
          numServicio: '',
          rut: '',
        },
      });
      dispatch(actualizarEstadoErrorInitCicloComercial(false));
    }
  }, [MODAL_RUT_NO_ENCONTRADO, errorInitCicloComercial, estadoModalListadoDeServicios]);

  /**
   * useEffect para cargar el queryparams de numero de servicio
   *
   */
  useEffect(() => {
    const data = {
      numero: formik.values.numServicio || queryNumeroServicio,
    };
    tagFacturacionPantallaIncio(data);
    formik.values.numServicio = queryNumeroServicio;
  }, []);

  /**
   *  UseEffect para cambiar el estado a `true` el inicio de la pagina después del primer renderizado
   * */
  useEffect(() => {
    setInicioPagina(true);
  }, []);

  return (
    <>
      {estadoModal && <ModalNumeroCliente cerrarModal={cerrarModal} />}
      <div className={styles.contenedorInternoData}>
        {busquedaPorRutAtivada && (
          <Typography className={styles.seleccion}>
            {t('tracking:paginas.tracking.cicloFacturacion.busquedaPorRut.itemBusquedas.tituloBusqueda')}
          </Typography>
        )}
        {busquedaPorRutAtivada && <SubTabs dataBoton={dataBtnBusqueda} dataTestId="tab-busquedas" itemSeleccionado={tabSeleccionado} />}

        {itemSubTab === TipoTab.NUMERO_CLIENTE && (
          <TextField
            id="outlined-basic"
            name="numServicio"
            onInput={handleInput}
            onChange={event => {
              valorRut.formik.handleChange(event);
            }}
            className={styles.inputNumero}
            value={formik.values.numServicio}
            label={t('tracking:paginas.tracking.cicloFacturacion.busquedaPorRut.itemBusquedas.busquedaNumero.textoInput')}
            variant="outlined"
            type="text"
            error={formik.touched.numServicio && Boolean(formik.errors.numServicio)}
            helperText={formik.touched.numServicio && formik.errors.numServicio}
            InputLabelProps={{
              classes: {
                root: styles.labelRoot,
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton onClick={abrirModal} data-testid="boton-abrir-modal">
                    <img src={ICONO_INFO_CIRCLE} alt="Facturacion" />
                  </IconButton>
                </InputAdornment>
              ),
              classes: {
                root: styles.inputRoot,
                input: styles.inputInput,
              },
            }}
          />
        )}
        {itemSubTab === TipoTab.RUT && (
          <TextField
            id="outlined-basic"
            className={styles.inputRut}
            label={t('tracking:paginas.tracking.cicloFacturacion.busquedaPorRut.itemBusquedas.busquedaRut.textoInput')}
            variant="outlined"
            type="text"
            name="rut"
            value={formik.values.rut}
            onInput={handleInputRut}
            onChange={handleChangeRUT}
            onBlur={onHandleBlur}
            error={formik.touched.rut && Boolean(formik.errors.rut)}
            helperText={formik.touched.rut && formik.errors.rut}
            InputLabelProps={{
              classes: {
                root: styles.labelRoot,
              },
            }}
            InputProps={{
              classes: {
                root: styles.inputRoot,
                input: styles.inputInput,
              },
            }}
          />
        )}
      </div>
    </>
  );
};

export default ContenedorDeItemBusqueda;
