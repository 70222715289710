export const ROOT = '';
export const CASACERRADA = '/ciclo-facturacion';
/**
 * Header | SideMenu
 */
export const QUEINESOMOS = 'https://web.gruposaesa.cl/web/guest/quienes-somos/nuestras-empresas';
export const INVERSIONISTA = 'https://web.gruposaesa.cl/web/guest/inversionistas/memoria-anual';
export const GRUPOSAESA = 'https://web.gruposaesa.cl/web/guest/inicio';
export const HERRAMIENTAS = 'https://web.gruposaesa.cl/web/saesa/tendido-electrico';
export const CONTACTO = 'https://web.gruposaesa.cl/web/saesa/red-de-oficinas';
export const INDICADORES = 'https://web.gruposaesa.cl/web/saesa/indicadores/conexion-y-ampliacion-de-servicios/';
export const CLIENTEHOGAR = 'https://web.gruposaesa.cl/web/saesa/cliente-hogar';
export const CLIENTEMPRESA = 'https://web.gruposaesa.cl/web/saesa/cliente-empresa';
export const SOMOSVECINOS = 'https://web.gruposaesa.cl/sustentabilidad/sustentabilidad-en-la-comunidad/somos-vecinos';
/**
 * Footer
 */
export const LINK_FRONTEL = 'https://web.gruposaesa.cl/web/frontel';
export const LINK_SAESA = 'https://web.gruposaesa.cl/web/saesa';
export const LINK_LUZ_OSORNO = 'https://web.gruposaesa.cl/web/luz-osorno';
export const LINK_EDELAYSEN = 'https://web.gruposaesa.cl/web/edelaysen';

export const LINK_QUIENES_SOMOS = 'https://web.gruposaesa.cl/quienes-somos';
export const LINK_INVERSIONISTAS = 'https://web.gruposaesa.cl/inversionistas';
export const LINK_PROOVEDOR = 'https://web.gruposaesa.cl/proveedores-y-contratistas';
export const LINK_SUSTENTABILIDAD = 'https://web.gruposaesa.cl/sustentabilidad';
export const LINK_TRABAJA_CON_NOSOTROS = 'https://web.gruposaesa.cl/personas/trabaja-con-nosotros';
export const LINK_RED_OFICINAS = 'https://web.gruposaesa.cl/web/saesa/red-de-oficinas';
export const LINK_CONTACTANOS = 'https://web.gruposaesa.cl/consultas-y-reclamos';

export const LINK_PREGUNTAS_FRECUENTES = 'https://web.gruposaesa.cl/documentacion/preguntas-frecuentes';
export const LINK_GLOSARIO = 'https://web.gruposaesa.cl/documentacion/glosario';
export const LINK_TERMINO_USO =
  'https://web.gruposaesa.cl/documents/20121/42825/Terminos-de-Uso-para-sitio-web.pdf/b3b8a8e7-29ff-ea40-6c28-3e50661af8d7?t=1612823333137';
export const LINK_POLITICA_PRIVACIDAD =
  'https://web.gruposaesa.cl/documents/20121/42825/Politica-privacidad-y-proteccion-de-datos-personales-para-sitio-web.pdf/16edfa19-54fb-39af-0f36-cc647859d338?t=1612823332473';
