import { createStyles, makeStyles, Theme } from '@material-ui/core';

const WidgetTrackingHelperStyles = makeStyles((theme: Theme) =>
  createStyles({
    contenedor: {
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '16px 20px',
      gap: '24px',
      width: '733px',
      background: '#EBF9FF',
      border: ' 1px solid var(--azul-info)',
      borderRadius: '10px',
      flex: 'none',
      order: 1,
      flexGrow: 0,
      marginBottom: '34px',
      [theme.breakpoints.down('sm')]: {
        gap: '12px',
        width: '100%',
        alignItems: 'flex-start',
        marginBottom: '24px',
      },
    },
    contenedorInformacion: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '0px',
      gap: '16px',
      width: '693px',
      flex: 'none',
      order: 0,
      flexGrow: 1,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        gap: '12px',
        alignItems: 'flex-start',
      },
    },
    contenedorTexto: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '0px',
      flex: 'none',
      order: 1,
      flexGrow: 1,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    textoParrafo: {
      width: '653px',
      fontFamily: 'Neutrif Studio',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '18px',
      color: 'var(--gris-l3)',
      flex: 'none',
      order: 0,
      alignSelf: 'stretch',
      flexGrow: 0,
      [theme.breakpoints.down('sm')]: {
        width: '85%',
      },
    },
  })
);

export default WidgetTrackingHelperStyles;
