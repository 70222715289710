import { createStyles, makeStyles, Theme } from '@material-ui/core';

const footerStyles = makeStyles((theme: Theme) =>
  createStyles({
    contenido: {
      display: 'flex',
      justifyContent: 'center',
      background: 'var(--secundario-gris-azulado)',
      [theme.breakpoints.down('sm')]: {
        padding: '3rem',
      },
    },
    contenedorFooter: {
      '--ancho-maximo-grid': '1090px',
      maxWidth: 'var(--ancho-maximo-grid)',
    },
    bottom: {
      background: 'var(--primario-azul-oscuro)',
      borderBottom: '1rem solid var(--primario-neon)',
      padding: '2rem 0',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
    seccion: {
      width: '100%',
    },
    logo: {
      background: 'transparent',
      border: 'none',
      cursor: 'pointer',
      [theme.breakpoints.down('sm')]: {
        width: 'auto',
        display: 'flex',
        justifyContent: 'center',
        paddingRight: '1rem',
      },
    },
    imagen: {
      objectFit: 'cover',
      height: 'auto',
      [theme.breakpoints.down('sm')]: {
        width: '160px',
      },
    },
    icono: {
      marginRight: '0.625rem',
    },
    textoLista: {
      color: 'var(--primario-azul-oscuro)',
      textAlign: 'start',
      textDecoration: 'none',
    },
    textoBottom: {
      color: 'var(--blanco)',
      textAlign: 'start',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
    link: {
      justifyContent: 'center',
      marginRight: '2rem',
      [theme.breakpoints.down('sm')]: {
        marginRight: '0',
      },
    },
    linea: {
      borderRight: '2px solid var(--gris-l63)',
      [theme.breakpoints.down('sm')]: {
        borderBottom: '2px solid var(--gris-l63)',
        borderRight: 'none',
      },
    },
    secciones: {
      height: 'fit-content',
    },
  })
);

export default footerStyles;
