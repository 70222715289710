import { Button, Hidden } from '@material-ui/core';
import { Card, FlechaAtrasIcon, Typography } from '@saesa/ui-kit-front';
import clsx from 'clsx';
import { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { CICLO_FACTURACION, CICLO_FACTURACION_DETALLE_TRAMO, CICLO_FACTURACION_SLASH, CORTE, POST_VENTA } from 'navigation';
import useStyles from './PagesItemStyle';

const PagesItem = ({
  dataTestId,
  children,
  titulo,
  path,
  textoVolver,
  onClickVolver,
}: {
  dataTestId?: string;
  children: ReactNode;
  titulo: string;
  path: string;
  textoVolver?: string;
  onClickVolver?: () => void;
}) => {
  const styles = useStyles();
  const location = useLocation();
  useEffect(() => undefined, [location]);

  /**
   * Hook que permite obtener la ruta actual
   */
  const { pathname } = useLocation();

  /**
   * Función que establece si el usuario esta en facturacion o en facturacion/
   * @returns boleano
   */
  function isFacturacionPath(): boolean {
    return (
      ((path === CICLO_FACTURACION || path === CICLO_FACTURACION_SLASH) &&
        (pathname === CICLO_FACTURACION || pathname === CICLO_FACTURACION_SLASH)) ||
      (path === CORTE && pathname === CORTE) ||
      (path === POST_VENTA && pathname === POST_VENTA)
    );
  }

  return (
    <Card className={styles.pagesItem}>
      <section data-testid={dataTestId} className={styles.header}>
        <Button className={clsx(styles.boton, { [styles.ocultar]: isFacturacionPath() })} type="button" onClick={onClickVolver}>
          <Typography strong="semi-bold" variant="parrafo" className={styles.title}>
            <FlechaAtrasIcon className={styles.flecha} />
            <Hidden only={['xs', 'sm']}>{textoVolver}</Hidden>
          </Typography>
        </Button>
        <Typography
          strong="semi-bold"
          variant="h3"
          className={location.pathname === CICLO_FACTURACION_DETALLE_TRAMO ? styles.titleTramo : styles.title}
        >
          {titulo}
        </Typography>
      </section>
      <section className={styles.body}>{children}</section>
    </Card>
  );
};

PagesItem.defaultProps = {
  dataTestId: '',
  textoVolver: '',
  onClickVolver: () => undefined,
};

export default PagesItem;
