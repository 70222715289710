import { Button } from '@material-ui/core';
import { ExportIcon, Typography } from '@saesa/ui-kit-front';
import { URL_ESTOY_SIN_LUZ } from 'navigation';
import useStyles from 'pages/Styles/VistasCorteStyles';
import { useTranslation } from 'react-i18next';

const TextoInferiorReportar = ({ titulo }: { titulo: string }) => {
  /**
   * Componente de traduccion i18n
   */
  const { t } = useTranslation();
  /**
   * Componente de hoja de estilos
   */
  const styles = useStyles();
  /**
   * Metodo que redirecciona a sitio publico para reportar un corte
   */
  const reportarCorte = () => {
    window.open(URL_ESTOY_SIN_LUZ, '_blank');
  };
  return (
    <div className={styles.infoCnt}>
      <Typography strong="normal" variant="parrafo" className={styles.info}>
        {titulo}
      </Typography>
      <Button className={styles.btnReportar} onClick={reportarCorte} variant="outlined" color="primary" type="submit">
        {t('tracking:paginas.tracking.corte.reporteCorte.textoReportarCorte')}
        <ExportIcon className={styles.iconReportar} />
      </Button>
    </div>
  );
};

export default TextoInferiorReportar;
