import { Box, Grid } from '@material-ui/core';
import { Typography } from '@saesa/ui-kit-front';
import { imgLosMejores } from 'assets/images';
import { obtenerContenedorFooterRedireccionXs12, obtenerContenedorFooterRedireccionXs6Md12 } from 'helper/tracking.helper';
import { GRUPO_SAESA_WEB_URL } from 'navigation';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  LINK_CONTACTANOS,
  LINK_EDELAYSEN,
  LINK_FRONTEL,
  LINK_GLOSARIO,
  LINK_INVERSIONISTAS,
  LINK_LUZ_OSORNO,
  LINK_POLITICA_PRIVACIDAD,
  LINK_PREGUNTAS_FRECUENTES,
  LINK_PROOVEDOR,
  LINK_QUIENES_SOMOS,
  LINK_RED_OFICINAS,
  LINK_SAESA,
  LINK_SUSTENTABILIDAD,
  LINK_TERMINO_USO,
  LINK_TRABAJA_CON_NOSOTROS,
} from 'root-page';
import { RootState } from 'store';
import Arroba from './Arroba';
import useStyles from './FooterStyles';
import PuntoMapa from './PuntoMapa';
import Telefono from './Telefono';

const Footer = ({ className }: { className: string }) => {
  const styles = useStyles();

  const logo = useSelector((state: RootState) => state.tracking.distribuidora);

  const { t } = useTranslation();

  return (
    <footer className={className}>
      <Box paddingX={{ xs: 3, md: 3 }} paddingY={{ xs: 3, md: 9 }} className={styles.contenido}>
        <Grid className={styles.contenedorFooter} container spacing={4}>
          <Grid container item xs={12} md={4}>
            <Grid className={styles.link} item xs={6} md={4}>
              <a className={styles.logo} href={GRUPO_SAESA_WEB_URL} target="_blank" rel="noreferrer">
                <img className={styles.imagen} src={logo.urlLogo} alt="" />
              </a>
            </Grid>
            <Grid item xs={6} md={6}>
              <a className={styles.logo} href="/" target="_blank" rel="noreferrer">
                <img className={styles.imagen} src={imgLosMejores} alt="" />
              </a>
            </Grid>
          </Grid>
          <Grid container item xs={12} md={2} className={styles.linea}>
            <Box width="100%" marginBottom={1}>
              <Typography className={styles.textoLista} variant="h2" strong="bold">
                Distribuidoras
              </Typography>
            </Box>
            {obtenerContenedorFooterRedireccionXs6Md12(styles, t('footer.frontel'), LINK_FRONTEL)}
            {obtenerContenedorFooterRedireccionXs6Md12(styles, t('footer.saesa'), LINK_SAESA)}
            {obtenerContenedorFooterRedireccionXs6Md12(styles, t('footer.luzOsorno'), LINK_LUZ_OSORNO)}
            {obtenerContenedorFooterRedireccionXs6Md12(styles, t('footer.edelaysen'), LINK_EDELAYSEN)}
          </Grid>
          <Grid className={styles.linea} container item xs={12} md={4}>
            <Grid item className={styles.secciones} xs={12}>
              <Box marginBottom={1}>
                <Typography className={`${styles.textoLista} ${styles.seccion}`} variant="h2" strong="bold">
                  Secciones
                </Typography>
              </Box>
            </Grid>
            <Grid container item xs={6}>
              {obtenerContenedorFooterRedireccionXs12(styles, t('footer.quienesSomos'), LINK_QUIENES_SOMOS)}
              {obtenerContenedorFooterRedireccionXs12(styles, t('footer.inversionistas'), LINK_INVERSIONISTAS)}
              {obtenerContenedorFooterRedireccionXs12(styles, t('footer.proveedores'), LINK_PROOVEDOR)}
              {obtenerContenedorFooterRedireccionXs12(styles, t('footer.sustentabilidad'), LINK_SUSTENTABILIDAD)}
            </Grid>
            <Grid container item xs={6}>
              {obtenerContenedorFooterRedireccionXs12(styles, t('footer.trabajaConNosotros'), LINK_TRABAJA_CON_NOSOTROS)}
              {obtenerContenedorFooterRedireccionXs12(styles, t('footer.redDeOficinas'), LINK_RED_OFICINAS)}
              {obtenerContenedorFooterRedireccionXs12(styles, t('footer.contactanos'), LINK_CONTACTANOS)}
            </Grid>
          </Grid>
          <Grid container item xs={12} md={2}>
            <Box marginBottom={1}>
              <Typography className={styles.textoLista} variant="h2" strong="bold">
                Ayuda
              </Typography>
            </Box>
            {obtenerContenedorFooterRedireccionXs12(styles, t('footer.preguntasFrecuentes'), LINK_PREGUNTAS_FRECUENTES)}
            {obtenerContenedorFooterRedireccionXs12(styles, t('footer.glosario'), LINK_GLOSARIO)}
            {obtenerContenedorFooterRedireccionXs12(styles, t('footer.terminoUso'), LINK_TERMINO_USO)}
            {obtenerContenedorFooterRedireccionXs12(styles, t('footer.politicaPrivacidad'), LINK_POLITICA_PRIVACIDAD)}
          </Grid>
        </Grid>
      </Box>
      <Grid container item xs={12} className={styles.bottom}>
        <Grid item xs="auto" md={3} />
        <Grid item xs={12} md={2}>
          <Typography className={styles.textoBottom} variant="h2">
            <Arroba className={styles.icono} />
            {t('footer.email')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography className={styles.textoBottom} variant="h2">
            <Telefono className={styles.icono} />
            {t('footer.telefono')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography className={styles.textoBottom} variant="h2">
            <PuntoMapa className={styles.icono} />
            {t('footer.localidad')}
          </Typography>
        </Grid>
        <Grid />
      </Grid>
    </footer>
  );
};

export default Footer;
