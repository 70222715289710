import { EDELAYSEN_LOGO, FRONTEL_LOGO, GRUPOSAESA_LOGO, LUZOSORNO_LOGO, SAESA_LOGO } from 'assets/images';
import { TipoDistribuidora } from 'config';
import { SetStateAction } from 'react';
import { esDatoValido } from './trackingUtils';

let logo = '';

const manejarLogo = (codEmpresa: string): any => {
  switch (codEmpresa) {
    case TipoDistribuidora.SAESA:
      return SAESA_LOGO;
    case TipoDistribuidora.FRONTEL:
      return FRONTEL_LOGO;
    case TipoDistribuidora.EDELAYSEN:
      return EDELAYSEN_LOGO;
    case TipoDistribuidora.LUZ_OSORNO:
      return LUZOSORNO_LOGO;
    default:
      return GRUPOSAESA_LOGO;
  }
};

/**
 * Maneja el logo al seleccionar un tipo de servicio en el selector
 * @param servicio arreglo de data de servicios proveniente de reduxta
 * @param nuevoNumeroServicio numero de servicio seleccionado
 * @returns string
 */
export const obtenerLogo = (tipoEmpresa: string | null): SetStateAction<string> => {
  if (esDatoValido(tipoEmpresa)) {
    logo = manejarLogo(tipoEmpresa!);
  } else {
    logo = GRUPOSAESA_LOGO;
  }
  return logo;
};
