import { createStyles, makeStyles, Theme } from '@material-ui/core';

const ModalRutNoEncontradoStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalGlobal: {
      gap: '12px',
    },
    tituloTexto: {
      width: 'auto',
      [theme.breakpoints.down('sm')]: {
        width: '95%',
      },
    },
    contenedorInterno: {
      display: 'flex',
      flexDirection: 'column',
      gap: '12px',
      maxWidth: '364px',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
    },
    cambiarTamanioBoton: {
      width: 'auto',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
        width: '100%',
      },
    },
  })
);

export default ModalRutNoEncontradoStyles;
