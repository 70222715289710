import { format as formatDate, formatDistance, formatRelative, isDate } from 'date-fns';
import { es } from 'date-fns/locale';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enCommon from './en/common.json';
import enErrors from './en/errors.json';
import enTracking from './en/tracking.json';
import esCommon from './es/common.json';
import esErrors from './es/errors.json';
import esTracking from './es/tracking.json';

export const resources = {
  en: {
    translation: enCommon,
    tracking: enTracking,
    errors: enErrors,
  },
  es: {
    translation: esCommon,
    tracking: esTracking,
    errors: esErrors,
  },
} as const;
i18n.use(initReactI18next).init({
  lng: 'es',
  resources,
  ns: ['translation', 'home', 'errors'],

  interpolation: {
    escapeValue: false,
    format: (value, format) => {
      if (isDate(value)) {
        const locale = es;

        if (format === 'short') return formatDate(value, 'P', { locale });
        if (format === 'long') return formatDate(value, 'PPPP', { locale });
        if (format === 'relative') return formatRelative(value, new Date(), { locale });
        if (format === 'ago')
          return formatDistance(value, new Date(), {
            locale,
            addSuffix: true,
          });

        return formatDate(value, format!);
      }
      return value;
    },
  },
});
