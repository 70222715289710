import { createSlice } from '@reduxjs/toolkit';
import { INITIAL_STATE } from 'helper/tracking.helper';
import { TrackingState } from './trackingState.interface';

const initialState: TrackingState = INITIAL_STATE;

export const trackingSlice = createSlice({
  name: 'tracking',
  initialState,
  reducers: {
    actualizarLogo: (state, action) => {
      state.distribuidora.urlLogo = action.payload?.urlLogo || state.distribuidora.urlLogo;
    },
    actualizarTrackingCicloComercial: (state, action) => {
      state.ciclo = action.payload || state.ciclo;
    },
    actualizarTrackingCorte: (state, action) => {
      state.corte = action.payload || state.corte;
    },
    actualizarTrackingCicloComercialPendienteDePago: (state, action) => {
      state.ciclo.estado = action.payload;
    },
    actualizarUsuario: (state, action) => {
      state.usuario.codigoEstadoSuministro = action.payload.codigoEstadoSuministro || state.usuario.codigoEstadoSuministro;
      state.usuario.codDistribuidora = action.payload.codDistribuidora || state.usuario.codDistribuidora;
      state.usuario.comuna = action.payload.comuna || state.usuario.comuna;
      state.usuario.localidad = action.payload.localidad || state.usuario.localidad;
      state.usuario.numeroServicio = action.payload.numeroServicio || state.usuario.numeroServicio;
    },
    actualizarEstadoMenuAbierto: (state, action) => {
      state.menuAbierto = action.payload.menuAbierto;
    },
    actualizarFacturacion: (state, action) => {
      state.facturacion = action.payload || state.facturacion;
    },
    actualizarTicketsPostventa: (state, action) => {
      state.postventa.tickets = action.payload.tickets;
    },
    actualizarEstadosPostventa: (state, action) => {
      state.postventa.estados = action.payload.estados;
    },
    actualizarTicketSeleccionadoPostventa: (state, action) => {
      state.postventa.ticketSeleccionado = action.payload.ticketSeleccionado;
    },
    actualizarTramo: (state, action) => {
      state.dataTramo = action.payload;
    },
    actualizarEstadoDeFlagMPC: (state, action) => {
      state.flagMPC.widget = action.payload.widget;
      state.flagMPC.modal = action.payload.modal;
    },
    actualizarEstadoModalPaginaNoDisponible: (state, action) => {
      state.modalPaginaNoDisponible.tiempoCookie = action.payload.tiempoCookie;
      state.modalPaginaNoDisponible.activacionModal = action.payload.activacionModal;
    },
    actualizarEstadoFlag: (state, action) => {
      state.flagCargado = action.payload;
    },
    actualizarEstadoFlagPostVenta: (state, action) => {
      state.flagCargadoPostVenta = action.payload;
    },
    actualizarSubTab: (state, action) => {
      state.subTab = action.payload.subTab;
    },
    actualizarBusquedaPorRutActiva: (state, action) => {
      state.busquedaPorRutAtiva = action.payload;
    },
    actualizarEstadoModalListadoServicios: (state, action) => {
      state.estadoModalListadoDeServicios = action.payload;
    },
    actualizarServiciosBusquedaPorRut: (state, action) => {
      state.serviciosBusquedaPorRut = action.payload;
    },
    actualizarEstadoModalRutNoEncontrado: (state, action) => {
      state.errorRutNoEncontrado = action.payload;
    },
    actualizarEstadoErrorInitCicloComercial: (state, action) => {
      state.errorInitCicloComercial = action.payload;
    },
  },
});

export const {
  actualizarTrackingCicloComercial,
  actualizarLogo,
  actualizarUsuario,
  actualizarEstadoMenuAbierto,
  actualizarFacturacion,
  actualizarTrackingCorte,
  actualizarTicketsPostventa,
  actualizarEstadosPostventa,
  actualizarTicketSeleccionadoPostventa,
  actualizarTramo,
  actualizarEstadoDeFlagMPC,
  actualizarTrackingCicloComercialPendienteDePago,
  actualizarEstadoModalPaginaNoDisponible,
  actualizarEstadoFlag,
  actualizarEstadoFlagPostVenta,
  actualizarSubTab,
  actualizarBusquedaPorRutActiva,
  actualizarEstadoModalListadoServicios,
  actualizarServiciosBusquedaPorRut,
  actualizarEstadoModalRutNoEncontrado,
  actualizarEstadoErrorInitCicloComercial,
} = trackingSlice.actions;

export default trackingSlice.reducer;
