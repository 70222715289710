import { Typography } from '@saesa/ui-kit-front';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { SubTabModel } from 'services/models/SubTab.model';
import { actualizarSubTab } from 'store';
import useStyles from './SubTabsStyles';

const SubTabs = ({
  dataBoton,
  dataTestId,
  itemSeleccionado,
}: {
  dataBoton: SubTabModel[];
  dataTestId: string;
  itemSeleccionado: string;
}) => {
  /**
   * uso de estilos
   */
  const styles = useStyles();

  /**
   * Dispatch redux
   */
  const dispatch = useDispatch();

  /**
   * Metodo que setea el valor del tab seleccionado a state
   * @param tab tab seleccionado
   */
  const seleccionarTab = (tab: SubTabModel) => {
    dispatch(actualizarSubTab({ subTab: tab.opcion }));
  };

  return (
    <div className={styles.tabContainer}>
      {dataBoton.map((tab: SubTabModel) => (
        <button
          className={clsx(styles.tab, {
            [styles.tabSeleccionada]: tab.opcion === itemSeleccionado,
          })}
          type="button"
          onClick={() => seleccionarTab(tab)}
          data-testid={dataTestId.concat(tab.opcion)}
          key={tab.opcion}
          disabled={tab.opcion === itemSeleccionado}
        >
          <Typography className={styles.textoTab} dataTestId={dataTestId}>
            {tab.texto}
          </Typography>
        </button>
      ))}
    </div>
  );
};

export default SubTabs;
