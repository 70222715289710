/**
 * Establece una cookie con un nombre, un valor y una duración en días.
 *
 * @param name - El nombre de la cookie.
 * @param value - El valor de la cookie.
 * @param days - El número de días durante los cuales la cookie debe estar válida.
 * @param tiempoMostrarModal - Valor en milisegundos que se debe mostrar el modal
 */
const setCookie = (name: string, value: string, days: number, tiempoMostrarModal: number) => {
  const d = new Date();
  d.setTime(d.getTime() + days * tiempoMostrarModal);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${name}=${value || ''}; ${expires}; path=/`;
};

/**
 * Obtiene el valor de una cookie por su nombre.
 *
 * @param name - El nombre de la cookie a obtener.
 * @returns El valor de la cookie si existe, o null si no se encuentra.
 */
const getCookie = (name: string): string | null => {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    const c = ca[i].trim();
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length);
  }
  return null;
};

/**
 * Muestra un modal basado en la cookie especificada. El modal se muestra si no se ha mostrado en las últimas 24 horas.
 *
 * @param cookieName - El nombre de la cookie que controla la visibilidad del modal.
 * @param cookieValue - El nombre de la
 * @param tiempoMostrarModal - Valor en milisegundos que se debe mostrar el modal
 */
export const mostrarModalCookie = (cookieName: string, tiempoMostrarModal: number): boolean => {
  const lastShown = getCookie(cookieName);
  const now = new Date();
  const shouldShowModal = !lastShown || now.getTime() - new Date(lastShown).getTime() > tiempoMostrarModal;

  if (shouldShowModal) {
    setCookie(cookieName, now.toISOString(), 1, tiempoMostrarModal);
    return true;
  }
  return false;
};
